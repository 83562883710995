import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestGetList, requestGetCandidate, requestCandidateProfile, userLogout
} from "../../Redux/actions";
import { toast } from "react-toastify";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";
import CodiceFiscale from "codice-fiscale-js";
import TermsAndService from "../../Components/common/register-fields/TermsAndService";
import OtherTerms from "../../Components/common/register-fields/OtherTerms";
import { useNavigate } from "react-router-dom";
import CategoriesCheckbox from "./CategoriesCheckbox";
import QualificationLevel from "../../Components/common/register-fields/QualificationLevel";
import Driverlicense from "../../Components/common/register-fields/Driverlicense";
import FiscalCode from "../../Components/common/register-fields/FiscalCode";
import Cellphone from "../../Components/common/register-fields/Cellphone";
import LocationForm from "../../Components/common/register-fields/location/LocationForm";
import BasicInfoRegister from "../../Components/common/register-fields/basic-details/BasicInfoRegister";
import PasswordResetConfirm from "../../Components/forggot-password/Password-Reset-Confirm";


function Profile(props) {
  const [showModal, setShowModal] = useState('');
  const [selectedOptionsEducation, setSelectedOptionsEducation] = useState({
    submenu: "",
    subSubmenu: "",
  });
  const [data, setData] = useState({
    country: "Italia",
    phone: "+39",
    tel_visible: false,
    tel_consent: false,
    e_mail_consent: false,
    minority_group: false,
    travel_available: false,
  });

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const titleRef = useRef(null);
  const fiscalCodeRef = useRef(null);
  const phonRef = useRef();
  const birthDateRef = useRef(null);
  const qualificationRef = useRef(null);
  const subqualificationRef = useRef(null);
  const driverLicenseRef = useRef(null);
  const streetNumberRef = useRef(null);
  const zipCodeRef = useRef(null);
  const cityRef = useRef(null);
  const provinceRef = useRef(null);
  const countryRef = useRef(null);
  const eula_consentRef = useRef(null);
  const privacy_consentRef = useRef(null);

  const [error, setError] = useState(false);
  const [errorfirst_name, seterrorfirst_name] = useState("");
  const [errorlast_name, seterrorlast_name] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [errorphone, seterrorPhone] = useState("");
  const [errortitle, seterrorTitle] = useState("");
  const [errorfiscal_code, seterrorFiscal_Code] = useState("");
  const [errorqualification, seterrorqualification] = useState("");
  const [errorDriverlicense, seterrorDriverlicense] = useState("");
  const [errorStreetnumber, seterrorStreetnumber] = useState("");
  const [errorCountry, seterrorCountry] = useState("");
  const [errorCity, seterrorCity] = useState("");
  const [errorProvince, seterrorProvince] = useState("");
  const [errorZipcode, seterrorZipcode] = useState("");
  const [errorbirthDate, seterrorBirthDate] = useState("");
  const [driverlicenseData, setDriverlicenseData] = useState([]);
  const [modifydriverlicenseData, setModifyDriverlicenseData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSubMenu, setErrorSubMenu] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [errorprivacyAccepted, setErrorPrivacyAccepted] = useState("");
  const [privacyContentAccepted, setPrivacyContentAccepted] = useState(false);
  const [errorPrivacyContentAccepted, setErrorPrivacyContentAccepted] = useState("");
  const [userType, setUserType] = useState(false)

  const navigate = useNavigate();
  const { t } = useTranslation();

  const mystyle = {
    color: "#D10000",
    padding: "3px 10px",
    width: "100%",
  };

  useEffect(() => {
    let levelData = props.candidate.getlistData;
    if (levelData !== undefined) {
      if (levelData?.data?.Status === "Success") {
        const transformedDriverLicenseData =
          levelData.data.Message.drivingLicences.map((license) => ({
            value: license.id,
            label: license.licenceType,
          }));
        setDriverlicenseData(transformedDriverLicenseData);
        setTitleData(levelData.data.Message.Title);
        const processedCountries = levelData.data.Message.countries.map(
          (item) => {
            const name = item.nativeCountryName;
            return name;
          }
        );
        const uniqueCountries = [...new Set(processedCountries)];
        const uniqueCountryData = uniqueCountries.map((name) => ({
          nativeCountryName: name,
        }));

        setCountryData(uniqueCountryData);
        const municipalit = levelData?.data?.Message?.municipalities;
        const uniqueMunicipalities = [];
        const municipalityNames = new Set();
        municipalit.forEach((municipality) => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name);
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`,
            };
            uniqueMunicipalities.push(municipalityObject);
          }
        });
        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name,
          })) || [];

        setMunicipalities(transformedCityData);
      }
    }
  }, [props.candidate.getlistData]);

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === "Italia") return -1;
    if (b.nativeCountryName === "Italia") return 1;
    return 0;
  });


  useEffect(() => {
    props.requestGetList();
    const username = atob(localStorage.getItem("username"));
    const token = localStorage.getItem("token");

    if (username) {
      props.requestGetCandidate({
        data: {
          username: username
        },
        token: token
      });
    }
  }, []);

  useEffect(() => {
    let candidateDetails = props.candidate.GetCandidateData;

    if (candidateDetails !== undefined && candidateDetails?.data?.Status === "Success") {
      const UpdateData = candidateDetails?.data?.Message[0] || {};
      console.log(UpdateData,"UpdateData")
      setUserType(UpdateData.flag_employee)

      const updateObj = {
        first_name: UpdateData.name,
        last_name: UpdateData.surname,
        email: UpdateData.e_mail,
        phone: UpdateData.cellphone,
        fiscal_code: UpdateData.fiscal_code,
        title: UpdateData.title.title,
        qualification: UpdateData.educationalLevel.levelType,
        street_number: UpdateData?.address?.[0]?.street,
        zip_code: UpdateData?.address?.[0]?.zip_code,
        country: UpdateData?.address?.[0]?.country,
        city: UpdateData?.address?.[0]?.city,
        province: UpdateData?.address?.[0]?.province,
        minority_group: UpdateData.minority_group,
        birth_date: UpdateData.birthdate,
        travel_available: UpdateData.travel_available,
        tel_visible: UpdateData.tel_visible,
        tel_consent: UpdateData.tel_consent,
        e_mail_consent: UpdateData.e_mail_consent,
        // eula_consent: UpdateData.eula_consent,
        // privacy_consent: UpdateData.privacy_consent,
      };
      setData(updateObj);
      setPrivacyAccepted(UpdateData.eula_consent);
      setPrivacyContentAccepted(UpdateData.privacy_consent);

      if (updateObj.country === 'Italia') {
        const UpdateCity = {
          value: municipalities.length,
          label: `${updateObj.city}, ${updateObj.province}`,
        };
        setSelectedCity(UpdateCity);
      }

      const educationalLevel = UpdateData.educationalLevel?.levelType || '';
      const [submenu = '', subSubmenu = ''] = educationalLevel.split(', ');

      setSelectedOptionsEducation({
        submenu: submenu || '',
        subSubmenu: subSubmenu || '',
      });

      const transformedDriverLicenseData = UpdateData?.driving_licence?.map((license, index) => ({
        value: index,
        label: license.licenceType,
      })) || [];

      setSelectedOptions(transformedDriverLicenseData);

      const licenseTypes = selectedOptions
        ? selectedOptions.map((option) => ({ licenceType: option.label }))
        : [];

      setModifyDriverlicenseData(licenseTypes)

    }
  }, [props.candidate.GetCandidateData]);


  function validateFname() {
    const firstName = firstNameRef.current.value;
    if (!firstName) {
      seterrorfirst_name("Inserisci il tuo nome.");
      return false;
    }
    const trimmedFirstName = firstName.trim();
    if (!trimmedFirstName) {
      seterrorfirst_name("Il nome non può essere vuoto.");
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(trimmedFirstName)) {
      seterrorfirst_name("Inserisci solo caratteri alfabetici.");
      return false;
    }
    seterrorfirst_name("");
    return true;
  }

  function validateLname() {
    const lastName = lastNameRef.current.value;
    if (!lastName) {
      seterrorlast_name("Inserisci il tuo cognome.");
      return false;
    }
    const trimmedLastName = lastName.trim();
    if (!trimmedLastName) {
      seterrorlast_name("Il cognome non può essere vuoto.");
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(trimmedLastName)) {
      seterrorlast_name("Inserisci solo caratteri alfabetici.");
      return false;
    }
    seterrorlast_name("");
    return true;
  }

  function validateEmail() {
    const email = emailRef.current.value;
    if (!email) {
      seterroremail("Inserisci la tua e-mail.");
      return false;
    }
    const trimmedEmail = email.trim();
    if (!trimmedEmail) {
      seterroremail("L'e-mail non può essere vuota.");
      return false;
    }
    if (
      !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      seterroremail("Inserisci un ID e-mail valido.");
      return false;
    }
    seterroremail("");
    return true;
  }

  const validatePhone = () => {
    let phone_no = phonRef?.current?.props.value;
    if (!phone_no) {
      seterrorPhone("Inserisci il numero di telefono.");
      return false;
    } else if (phone_no.length < 5) {
      seterrorPhone("Inserisci il numero di telefono.");
      return false;
    } else {
      seterrorPhone("");
      return true;
    }
  };

  function validateTitle() {
    const title = titleRef.current.value;
    if (!title) {
      seterrorTitle("Seleziona il titolo.");
      return false;
    }
    seterrorTitle("");
    return true;
  }

  const validateFiscalCode = () => {
    const fiscalCode = data?.fiscal_code;

    if (!fiscalCode) {
      seterrorFiscal_Code("Inserisci il Codice Fiscale");
      return false;
    }

    try {
      const cf = new CodiceFiscale(fiscalCode);

      if (cf.isValid()) {
        seterrorFiscal_Code("");
        return true;
      } else {
        seterrorFiscal_Code("Codice Fiscale non valido");
        return false;
      }
    } catch (error) {
      seterrorFiscal_Code("Codice Fiscale non valido");
      return false;
    }
  };

  const validateQualification = () => {
    if (!qualificationRef?.current?.props?.value) {
      seterrorqualification("Seleziona il tuo titolo di studio.");
      return false;
    } else {
      seterrorqualification("");
      return true;
    }
  };

  const validateSubMenu = () => {
    if (subqualificationRef?.current) {
      const subMenu = subqualificationRef.current.value;
      if (!subMenu) {
        setErrorSubMenu("Seleziona il tuo Indirizzo / Specializzazione.");
        subqualificationRef.current.focus();
        return false;
      }
    }
    setErrorSubMenu("");
    return true;
  };

  const validateDriverlicense = () => {
    if (selectedOptions.length === 0) {
      seterrorDriverlicense("Seleziona almeno una patente di guida.");
      return false;
    } else {
      seterrorDriverlicense("");
      return true;
    }
  };

  function validateStreetNumber() {
    const street_number = streetNumberRef.current.value;
    if (!street_number) {
      seterrorStreetnumber("Inserisci il numero civico.");
      return false;
    }
    const trimmedStreetnumber = street_number.trim();
    if (!trimmedStreetnumber) {
      seterrorStreetnumber("Il numero civico non può essere vuoto.");
      return false;
    }
    seterrorStreetnumber("");
    return true;
  }

  function validateZipCode() {
    const zip_code = zipCodeRef.current.value;
    if (!zip_code) {
      seterrorZipcode("Inserisci il tuo Cap.");
      return false;
    }
    const trimmedZipcode = zip_code.trim();
    if (!trimmedZipcode) {
      seterrorZipcode("Il CAP non può essere vuoto.");
      return false;
    }
    if (!/^\d+$/.test(trimmedZipcode)) {
      seterrorZipcode("Il CAP può contenere solo numeri.");
      return false;
    }
    if (trimmedZipcode.length < 5) {
      seterrorZipcode("Il CAP deve contenere almeno 5 cifre.");
      return false;
    }
    seterrorZipcode("");
    return true;
  }

  function validateCountry() {
    if (countryRef.current !== null) {
      const country = countryRef.current.value;
      if (!country) {
        seterrorCountry("Nazione non selezionato.");
        return false;
      }
      seterrorCountry("");
      return true;
    }
  }

  function validateProvince() {
    if (provinceRef?.current !== null) {
      const province = provinceRef?.current?.value;
      if (!province) {
        seterrorProvince("Inserisci la tua provincia.");
        return false;
      }
      seterrorProvince("");
      return true;
    } else {
      return true;
    }
  }

  const validateCity = () => {
    let city;

    if (data?.country === "Italia") {
      if (!selectedCity) {
        seterrorCity("Comune non selezionato.");
        return false;
      }
    } else {
      if (cityRef?.current) {
        city = cityRef.current.value ?? "";
        if (!city.trim()) {
          seterrorCity("Inserisci la tua Comune.");
          return false;
        }
      }
    }
    seterrorCity("");
    return true;
  };

  const validateBirthDate = () => {
    const date = birthDateRef.current.value;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!date) {
      seterrorBirthDate("inserisci una data di nascita valida.");
      return false;
    } else if (new Date(date).getTime() >= today.getTime()) {
      seterrorBirthDate("inserisci una data di nascita valida.");
      return false;
    } else {
      seterrorBirthDate("");
      return true;
    }
  };

  const validatePrivacy = () => {
    const privacy = eula_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyAccepted("Questo campo è obbligatorio.");
      return false;
    } else {
      setErrorPrivacyAccepted("");
      return true;
    }
  };

  const validatePrivacyContent = () => {
    const privacy = privacy_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyContentAccepted("Questo campo è obbligatorio.");
      return false;
    } else {
      setErrorPrivacyContentAccepted("");
      return true;
    }
  };

  const onChangeData = (e) => {
    const { name, value, type, files } = e.target;
    let newValue;

    if (type === "number") {
      newValue = value.replace(/\D/g, "").slice(0, 5);
    } else if (type === "file") {
      newValue = files[0];
    } else {
      newValue = value;
    }

    if (name === "country") {
      if (value === "Italia") {
        setData((prevData) => ({
          ...prevData,
          country: newValue,
          city: "",
          province: "",
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          country: newValue,
          city: "",
          province: "",
        }));
      }
    } else {
      setData((prevData) => ({ ...prevData, [name]: newValue }));
    }
  };

  useEffect(() => {
    validateSubMenu();
  }, [selectedOptionsEducation]);

  //=======================Only select and Inserted education level================================

  const handleEducationLevelChange = (e) => {
    setIsLoading(true);
    if (selectedOptions !== null) {
      setTimeout(() => {
        const updatedSelectedOptions = e ? e.label : "";
        setData((prevData) => ({ ...prevData, qualification: updatedSelectedOptions }));
        setSelectedOptionsEducation((prevOptions) => ({
          ...prevOptions,
          submenu: updatedSelectedOptions,
          subSubmenu: "",
        }));
        setIsLoading(false);
        seterrorqualification("");
      }, 50);
    } else {
      setData((prevData) => ({ ...prevData, qualification: "" }));
      setSelectedOptionsEducation((prevOptions) => ({
        ...prevOptions,
        submenu: "",
        subSubmenu: "",
      }));
      setIsLoading(false);
      validateQualification()
    }
  };

  const handleSubsubmenuChange = (e) => {
    const { value } = e.target;

    setSelectedOptionsEducation((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value,
      };

      const qualification = `${newOptions.submenu}, ${value}`;
      setData((prevData) => ({
        ...prevData,
        qualification,
      }));

      return newOptions;
    });
  };

  const handleFiscalCodeChange = (e) => {
    const { name, value, type } = e.target;
    let newValue;
    if (type === "text") {
      newValue = value.slice(0, 16);
      setData((prevData) => ({ ...prevData, [name]: newValue }));
    }
  };

  const handlePhoneChange = (value) => {
    if (typeof value !== "undefined") {
      if (!value.startsWith("+")) {
        value = "+" + value;
      }
      setData((prevData) => ({ ...prevData, phone: value }));
    }
  };

  const handleChangeDriver = (selectedOptions) => {
    const licenseTypes = selectedOptions
      ? selectedOptions.map((option) => ({ licenceType: option.label }))
      : [];
    setModifyDriverlicenseData(licenseTypes);
    setSelectedOptions(selectedOptions);
    seterrorDriverlicense("");
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    if (date.length < 11) {
      setData({ ...data, birth_date: date });
    }
  };

  const handleChangeCity = async (selectedOptions) => {
    setIsLoading(true);
    if (selectedOptions !== null) {
      setTimeout(() => {
        const [city, province] = selectedOptions?.label.split(", ");
        setData((prevData) => ({
          ...prevData,
          address: {
            ...prevData.address,
            city: city,
            province: province,
          },
        }));
        setSelectedCity(selectedOptions);
        setIsLoading(false);
        seterrorCity("");
      }, 1000);
    } else {
      setData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          city: "",
          province: "",
        },
      }));
      setSelectedCity(null);
      setIsLoading(false);
    }
  };

  const handleCheckboxChange1 = (e) => {
    setPrivacyAccepted(e.target.checked);
    if (e.target.checked) {
      setErrorPrivacyAccepted('');
    }
    validatePrivacy()
  };

  const handleCheckboxChange2 = (e) => {
    setPrivacyContentAccepted(e.target.checked);
    if (e.target.checked) {
      setErrorPrivacyContentAccepted('');
    }
    validatePrivacyContent()
  };


  function validateForm() {
    let fname = validateFname();
    let lname = validateLname();
    let email = validateEmail();
    let title = validateTitle();
    let ficalC = validateFiscalCode();
    let phonN = validatePhone();
    let qualificationT = validateQualification();
    let submenu = validateSubMenu();
    let driverlicenseV = validateDriverlicense();
    let streetNum = validateStreetNumber();
    let zipCode = validateZipCode();
    let countryV = validateCountry();
    let cityV = validateCity();
    let provinceV = validateProvince();
    let birthDateV = validateBirthDate();
    let privacy = validatePrivacy();
    let privacyContent = validatePrivacyContent();

    const validall =
      fname &&
      lname &&
      email &&
      phonN &&
      title &&
      ficalC &&
      qualificationT &&
      submenu &&
      driverlicenseV &&
      streetNum &&
      zipCode &&
      countryV &&
      cityV &&
      provinceV &&
      birthDateV &&
      privacy &&
      privacyContent;
    return validall;
  }

  useEffect(() => {
    if (error) {
      if (errortitle) {
        titleRef.current.focus();
      } else if (errorfirst_name) {
        firstNameRef.current.focus();
      } else if (errorlast_name) {
        lastNameRef.current.focus();
      } else if (errorbirthDate) {
        birthDateRef.current.focus();
      } else if (errorfiscal_code) {
        fiscalCodeRef.current.focus();
      } else if (errorphone) {
        phonRef?.current?.numberInputRef.focus();
      } else if (erroremail) {
        emailRef.current.focus();
      } else if (errorqualification) {
        qualificationRef.current.focus();
      } else if (errorSubMenu) {
        subqualificationRef.current.focus();
      } else if (errorDriverlicense) {
        driverLicenseRef.current.focus();
      } else if (errorCountry) {
        countryRef.current.focus();
      } else if (errorStreetnumber) {
        streetNumberRef.current.focus();
      } else if (errorCity) {
        cityRef.current.focus();
      } else if (errorProvince) {
        provinceRef.current.focus();
      } else if (errorZipcode) {
        zipCodeRef.current.focus();
      } else if (errorprivacyAccepted) {
        eula_consentRef.current.focus();
      } else if (errorPrivacyContentAccepted) {
        privacy_consentRef.current.focus();
      }

      setError(false);
    }
  }, [
    error,
    errortitle,
    errorfirst_name,
    errorlast_name,
    errorbirthDate,
    errorfiscal_code,
    errorphone,
    erroremail,
    errorqualification,
    errorSubMenu,
    errorDriverlicense,
    errorCountry,
    errorCity,
    errorProvince,
    errorStreetnumber,
    errorZipcode,
    errorprivacyAccepted,
    errorPrivacyContentAccepted
  ]);


  const handlePasswordUpdate = (newPassword) => {
    const token = localStorage.getItem("token");
    if (token) {
      props.requestCandidateProfile({
        data: {
          username: data.email,
          password: newPassword.password1,
          password2: newPassword.password2,
        },
        token: token
      });
    }
  };

  function onSubmitForm(e) {
    e.preventDefault();
    props.userLogout();
    const token = localStorage.getItem("token");
    if (validateForm()) {
      props.requestCandidateProfile({
        data: {
          username: data.email,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          cellphone: data.phone,
          name: data.first_name,
          surname: data.last_name,
          description: "Some description",
          title: {
            title: data.title,
          },
          educationalLevel: {
            levelType: data.qualification,
          },

          flag_agent: false,
          flag_employee: userType,
          flag_professional: false,
          fiscal_code: data.fiscal_code,
          contract_type: [
            {
              contract_name: "Contract 1",
            },
            {
              contract_name: "Contract 2",
            },
          ],
          availability: [
            {
              availability_type: "Availability 1",
            },
            {
              availability_type: "Availability 2",
            },
          ],
          sector: {
            sectorName: "Sector 1",
          },
          subsector: {
            subsector_name: "Subsector 1",
          },
          driving_licence: modifydriverlicenseData,
          birthdate: data.birth_date,
          document: null,
          minority_group: data.minority_group,
          travel_available: data.travel_available,
          tel_visible: data.tel_visible,
          tel_consent: data.tel_consent,
          e_mail_consent: data.e_mail_consent,
          eula_consent: privacyAccepted,
          privacy_consent: privacyContentAccepted,
          address: {
            street: data.street_number,
            city: data.city,
            zip_code: data.zip_code,
            state: null,
            province: data.province,
            country: data.country,
          },
        },
        token: token
      });
      setError(false);
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    let candidateProfile = props?.candidate?.CandidateProfileData;
    if (candidateProfile !== undefined) {
      if (candidateProfile?.data?.Status === "Success") {
        toast.success(candidateProfile?.data?.Status, { theme: "colored", autoClose: 1000 });
        props.candidate.CandidateProfileData = undefined;
        console.log(userType, "userType")
        if (userType) {
          navigate("/home");
        } else {
          navigate("/resume", { state: { username: data.email } });
        }
      } else {
        props.candidate.CandidateProfileData = undefined;
        toast.error(candidateProfile?.data?.Message, {
          theme: "colored",
          autoClose: 1000,
        });
        setError(true);
      }
    }
  }, [props.candidate.CandidateProfileData]);

  const handleOnPasswordConfirm = (name) => {
    setShowModal(name);
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  return (
    <>
      <Header />
      <div className="registration_sec">

        <div className="container">
          <div className="new_research_btn mt-4 text-end">
            <button
              className="btn btn_default btn_orange_outline"
              type="button"
              onClick={() => handleOnPasswordConfirm("password-confirm")}
            >
              Cambia password
            </button>
          </div>
          <div className="registration_title">
            {userType ? <h3>Completa la tua registrazione come Candidato</h3> :
              <h3>Completa la tua registrazione come Candidato</h3>}
          </div>
          <form className="registration_form" onSubmit={onSubmitForm}>
            <div className="row registration_row g-4">
              {/* <div className="registration_col form-group col-md-6 col-12">
                <div className="row g-2">
                  <div className="form-group col-md-2 col-12">
                    <label htmlFor="title" className="label">
                      {t("register.title")}
                    </label>
                    <select
                      className={`form-control ${errortitle ? "error" : ""}`}
                      name="title"
                      id="title"
                      value={data.title}
                      ref={titleRef}
                      onChange={onChangeData}
                      onBlur={validateTitle}>
                      <option value="">{t("register.title")}</option>
                      {titleData.map((option, index) => (
                        <option key={index} value={option.title}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                    {errortitle && <div style={mystyle}>{errortitle}</div>}
                  </div>
                  <div className="form-group col-md-10 col-12">
                    <label htmlFor="first_name" className="label">
                      {t("register.name")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorfirst_name ? "error" : ""
                        }`}
                      placeholder={t("register.name")}
                      id="first_name"
                      name="first_name"
                      ref={firstNameRef}
                      value={data.first_name || ""}
                      onChange={onChangeData}
                      onBlur={validateFname}
                    />
                    {errorfirst_name && (
                      <div style={mystyle}>{errorfirst_name}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="registration_col form-group col-md-6 col-12">
                <label htmlFor="last_name" className="label">
                  {t("register.surname")}
                </label>
                <input
                  type="text"
                  className={`form-control set_max_width ${errorlast_name ? "error" : ""
                    }`}
                  placeholder={t("register.surname")}
                  id="last_name"
                  name="last_name"
                  ref={lastNameRef}
                  value={data.last_name || ""}
                  onChange={onChangeData}
                  onBlur={validateLname}
                />
                {errorlast_name && <div style={mystyle}>{errorlast_name}</div>}
              </div>
              <div className="registration_col form-group col-md-6 col-12">
                <label className="label">{t("register.birth_date")}</label>
                <input
                  className={`form-control w-auto ${errorbirthDate ? "error" : ""
                    }`}
                  type="date"
                  name="birth_date"
                  id={"birth_date"}
                  ref={birthDateRef}
                  value={data.birth_date || ""}
                  onBlur={validateBirthDate}
                  onChange={handleDateChange}
                  placeholder={t("register.birth_date")}
                />
                {errorbirthDate && <div style={mystyle}>{errorbirthDate}</div>}
              </div> */}

              <BasicInfoRegister
                //================== title ========================
                data={data}
                titleData={titleData}
                onChangeData={onChangeData}
                validateTitle={validateTitle}
                titleRef={titleRef}
                errortitle={errortitle}

                // ==================== First Name ==========================

                validateFname={validateFname}
                firstNameRef={firstNameRef}
                errorfirst_name={errorfirst_name}

                // =====================Last Name ===========================

                validateLname={validateLname}
                lastNameRef={lastNameRef}
                errorlast_name={errorlast_name}

                // ===================== Birth Date ===========================

                handleDateChange={handleDateChange}
                validateBirthDate={validateBirthDate}
                birthDateRef={birthDateRef}
                errorbirthDate={errorbirthDate}

                mystyle={mystyle}
                t={t}
              />

              <FiscalCode
                data={data}
                fiscalCodeRef={fiscalCodeRef}
                handleFiscalCodeChange={handleFiscalCodeChange}
                validateFiscalCode={validateFiscalCode}
                errorfiscal_code={errorfiscal_code}
                mystyle={mystyle}
                t={t}
              />
              <Cellphone
                data={data}
                phonRef={phonRef}
                handlePhoneChange={handlePhoneChange}
                validatePhone={validatePhone}
                errorphone={errorphone}
                mystyle={mystyle}
                t={t}
              />
              <div className="registration_col form-group col-md-6 col-12">
                <label htmlFor="email" className="label">
                  {/* E-mail */}
                  {t("register.email")}
                </label>
                <input
                  type="email"
                  className={`form-control set_max_width ${erroremail ? "error" : ""
                    }`}
                  placeholder={t("register.email")}
                  id="email"
                  name="email"
                  ref={emailRef}
                  value={data.email || ""}
                  // onChange={onChangeData}
                  // onBlur={validateEmail}
                  readOnly
                />
                {erroremail && <div style={mystyle}>{erroremail}</div>}
              </div>
            </div>

            <OtherTerms
              data={data}
              handleCheckboxChange={handleCheckboxChange}
              t={t}
            />

            <div className="row registration_row g-4">
              <QualificationLevel
                selectedOptionsEducation={selectedOptionsEducation}
                handleEducationLevelChange={handleEducationLevelChange}
                validateQualification={validateQualification}
                qualificationRef={qualificationRef}
                errorqualification={errorqualification}
                handleSubsubmenuChange={handleSubsubmenuChange}
                validateSubMenu={validateSubMenu}
                subqualificationRef={subqualificationRef}
                errorSubMenu={errorSubMenu}
                mystyle={mystyle}
                t={t}
                isLoading={isLoading}
              />

              <Driverlicense
                driverlicenseData={driverlicenseData}
                selectedOptions={selectedOptions}
                handleChangeDriver={handleChangeDriver}
                validateDriverlicense={validateDriverlicense}
                driverLicenseRef={driverLicenseRef}
                errorDriverlicense={errorDriverlicense}
                mystyle={mystyle}
                t={t}
              />
            </div>
            <CategoriesCheckbox
              data={data}
              handleCheckboxChange={handleCheckboxChange}
            />

            <div className="contry_form">
              <h6 className="contry_form_title">
                {t("register.candidate.label")}
              </h6>

              <LocationForm
                data={data}

                //==================Country================

                countryRef={countryRef}
                onChangeData={onChangeData}
                validateCountry={validateCountry}
                sortedCountryData={sortedCountryData}
                errorCountry={errorCountry}

                //===========Street=========================

                validateStreetNumber={validateStreetNumber}
                streetNumberRef={streetNumberRef}
                errorStreetnumber={errorStreetnumber}

                //=============ProvinceCommue===============

                municipalities={municipalities}
                selectedCity={selectedCity}
                isLoading={isLoading}
                handleChangeCity={handleChangeCity}
                cityRef={cityRef}
                validateCity={validateCity}
                errorCity={errorCity}
                provinceRef={provinceRef}
                validateProvince={validateProvince}
                errorProvince={errorProvince}

                //=======ZipCode=====================

                zipCodeRef={zipCodeRef}
                validateZipCode={validateZipCode}
                errorZipcode={errorZipcode}

                mystyle={mystyle}
                t={t}
              />
            </div>

            <TermsAndService
              privacyAccepted={privacyAccepted}
              privacyContentAccepted={privacyContentAccepted}
              handleCheckboxChange1={handleCheckboxChange1}
              handleCheckboxChange2={handleCheckboxChange2}
              validatePrivacy={validatePrivacy}
              validatePrivacyContent={validatePrivacyContent}
              errorprivacyAccepted={errorprivacyAccepted}
              errorPrivacyContentAccepted={errorPrivacyContentAccepted}
              eula_consentRef={eula_consentRef}
              privacy_consentRef={privacy_consentRef}
              mystyle={mystyle}
              t={t}
            />

            <div className="registration_button text-center">
              <button
                type="submit"
                className="btn">
                {/* REgistrami! */}
                {t("register.candidate.button.save")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Update Pasword Modal */}
      {showModal === 'password-confirm' && (
        <div className="modal fade show custom_modal" tabIndex="-1" aria-labelledby="passwordConFirmModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <PasswordResetConfirm
                  onPasswordUpdate={handlePasswordUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetList, requestGetCandidate, requestCandidateProfile, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
