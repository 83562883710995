import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_REGISTER,
  receiveRegister,
  REQUEST_LOGIN,
  receiveLogin,
  REQUEST_GET_LIST,
  receiveGetList,
  REQUEST_GET_LOCATION,
  receiveGetLocation,
  REQUEST_GET_REGIONE_LIST,
  receiveGetRegioneList,
  REQUEST_GET_CANDIDATE,
  receiveGetCandidate,
  REQUEST_CANDIDATE_PROFILE,
  receiveCandidateProfile,
  REQUEST_GET_JOB,
  receiveGetJobDetails,
  REQUEST_GET_JOBLIST,
  receiveGetJobList,
  REQUEST_CANDIDATE_RESUME,
  receiveCandidateResume,
  REQUEST_GET_RESUME,
  receiveGetResumeDetails,
  REQUEST_UPDATE_RESUME,
  receiveUpdateResumeDetails,
  REQUEST_DELETE_RESUME_FILE,
  receiveDeleteResumeFile,
  REQUEST_DELETE_LETTER_FILE,
  receiveDeleteLetterFile,
  REQUEST_GET_EDUCATION_LEVEL_LIST,
  receiveGetEducationLevelList,
  REQUEST_GET_SECTOR_LIST,
  receiveGetSectorsList,

  //======================== Company ====================================
  REQUEST_COMPANY_REGISTER,
  receiveCompanyRegister,
  REQUEST_COMPANY_DETAILS,
  receiveCompanyDetails,
  REQUEST_COMPANY_UPDATE,
  receiveCompanyUpdate,
  REQUEST_COMPANY_PACKAGE,
  receiveCompanyPackage,
  REQUEST_COMPANY_CREDITE,
  receiveCompanyCredite,
  REQUEST_COMPANY_REFERENCE,
  receiveCompanyReference,
  REQUEST_COMPANY_JWT,
  receiveCompanyJWT,
  REQUEST_COMPANY_GET_CV_LIST,
  receiveGetCVList,
  receiveGetProducts,
  REQUEST_GET_PRODUCTS,
} from "./actions";

import {
  register,
  login,
  getlist,
  getlocation,
  getRegionelist,
  GetCandidate,
  candidateProfile,
  candidateresume,
  GetResumeDetails,
  UpdateResumeDetails,
  DeleteResumeFile,
  DeleteLetterFile,
  GetJobDetails,
  GetJobList,
  getEducationlevel,
  getSectors,
  getProducts,
} from "./api";

import {
  companyRegister,
  companyDetails,
  companyProfile,
  CompanyJWTToken,
  GetCompanyReference,
  packageToken,
  CreditToken,
  GetCVList,
} from "./companyApi";

export function* registerCandidate(action) {
  try {
    const response = yield call(register, action.obj);

    yield put(receiveRegister(response));
  } catch (e) {
    console.log(e);
    // console.log(e.message)
  }
}

export function* loginCandidate(action) {
  try {
    const response = yield call(login, action.obj);

    yield put(receiveLogin(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* getListlevel(action) {
  try {
    const response = yield call(getlist, action.obj);

    yield put(receiveGetList(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* getLocationData(action) {
  try {
    const response = yield call(getlocation, action.obj);

    yield put(receiveGetLocation(response));
  } catch (e) {
    console.log(e.message);
  }
}

//================= regione list ===============================

export function* getRegionelistData(action) {
  try {
    const response = yield call(getRegionelist, action.obj);

    yield put(receiveGetRegioneList(response));
  } catch (e) {
    console.log(e.message);
  }
}

//================= educational level ===============================

export function* getEducationlevelList(action) {
  try {
    const response = yield call(getEducationlevel, action.obj);

    yield put(receiveGetEducationLevelList(response));
  } catch (e) {
    console.log(e.message);
  }
}

//================= sector level ===============================

export function* getListSectorlevel(action) {
  try {
    const response = yield call(getSectors, action.obj);

    yield put(receiveGetSectorsList(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetCandidateDetails(action) {
  try {
    const response = yield call(GetCandidate, action.obj);

    yield put(receiveGetCandidate(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* candidateProfileData(action) {
  try {
    const response = yield call(candidateProfile, action.obj);

    yield put(receiveCandidateProfile(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* resumeCandidate(action) {
  try {
    const response = yield call(candidateresume, action.obj);

    yield put(receiveCandidateResume(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetResumeData(action) {
  try {
    const response = yield call(GetResumeDetails, action.obj);

    yield put(receiveGetResumeDetails(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* UpdateResumeData(action) {
  try {
    const response = yield call(UpdateResumeDetails, action.obj);

    yield put(receiveUpdateResumeDetails(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteResumeFileData(action) {
  try {
    const response = yield call(DeleteResumeFile, action.obj);

    yield put(receiveDeleteResumeFile(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteLetterFileData(action) {
  try {
    const response = yield call(DeleteLetterFile, action.obj);

    yield put(receiveDeleteLetterFile(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetJobData(action) {
  try {
    const response = yield call(GetJobDetails, action.obj);

    yield put(receiveGetJobDetails(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetJobListData(action) {
  try {
    const response = yield call(GetJobList, action.obj);

    yield put(receiveGetJobList(response));
  } catch (e) {
    console.log(e.message);
  }
}

//======================================= Company ==========================================

export function* registerCompany(action) {
  try {
    const response = yield call(companyRegister, action.obj);

    yield put(receiveCompanyRegister(response));
  } catch (e) {
    console.log(e);
  }
}

export function* GetCompanyDetails(action) {
  try {
    const response = yield call(companyDetails, action.obj);

    yield put(receiveCompanyDetails(response));
  } catch (e) {
    console.log(e);
  }
}

export function* CompanyDetailsUpdate(action) {
  try {
    const response = yield call(companyProfile, action.obj);

    yield put(receiveCompanyUpdate(response));
  } catch (e) {
    console.log(e);
  }
}

export function* GetCompanyReferenceData(action) {
  try {
    const response = yield call(GetCompanyReference, action.obj);

    yield put(receiveCompanyReference(response));
  } catch (e) {
    console.log(e);
  }
}

export function* CompanyJWTTokenData(action) {
  try {
    const response = yield call(CompanyJWTToken, action.obj);

    yield put(receiveCompanyJWT(response));
  } catch (e) {
    console.log(e);
  }
}

export function* GetPackageData(action) {
  try {
    const response = yield call(packageToken, action.obj);

    yield put(receiveCompanyPackage(response));
  } catch (e) {
    console.log(e);
  }
}

export function* GetCrediteData(action) {
  try {
    const response = yield call(CreditToken, action.obj);

    yield put(receiveCompanyCredite(response));
  } catch (e) {
    console.log(e);
  }
}

export function* GetCVListData(action) {
  try {
    const response = yield call(GetCVList, action.obj);

    yield put(receiveGetCVList(response));
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetProductsData(action) {
  try {
    const response = yield call(getProducts, action.obj);

    yield put(receiveGetProducts(response));
  } catch (e) {
    console.log(e.message);
  }
}

export default function* mainSaga() {
  yield takeLatest(REQUEST_REGISTER, registerCandidate);
  yield takeLatest(REQUEST_LOGIN, loginCandidate);
  yield takeLatest(REQUEST_GET_LIST, getListlevel);
  yield takeLatest(REQUEST_GET_LOCATION, getLocationData);

  //=======================region list ===============================================

  yield takeLatest(REQUEST_GET_REGIONE_LIST, getRegionelistData);

  //================= educational level and sector level ===============================

  yield takeLatest(REQUEST_GET_EDUCATION_LEVEL_LIST, getEducationlevelList);
  yield takeLatest(REQUEST_GET_SECTOR_LIST, getListSectorlevel);

  yield takeLatest(REQUEST_GET_CANDIDATE, GetCandidateDetails);
  yield takeLatest(REQUEST_CANDIDATE_PROFILE, candidateProfileData);

  //======================== get resume =========================

  yield takeLatest(REQUEST_GET_RESUME, GetResumeData);
  //======================== post resume =========================

  yield takeLatest(REQUEST_CANDIDATE_RESUME, resumeCandidate);
  //======================== update resume =========================
  yield takeLatest(REQUEST_UPDATE_RESUME, UpdateResumeData);

  //======================== delete resume file =========================
  yield takeLatest(REQUEST_DELETE_RESUME_FILE, DeleteResumeFileData);

  //======================== delete letter file =========================
  yield takeLatest(REQUEST_DELETE_LETTER_FILE, DeleteLetterFileData);

  yield takeLatest(REQUEST_GET_JOB, GetJobData);
  yield takeLatest(REQUEST_GET_JOBLIST, GetJobListData);

  //================================================ Company =================================
  yield takeLatest(REQUEST_COMPANY_REGISTER, registerCompany);
  yield takeLatest(REQUEST_COMPANY_DETAILS, GetCompanyDetails);
  yield takeLatest(REQUEST_COMPANY_UPDATE, CompanyDetailsUpdate);
  yield takeLatest(REQUEST_COMPANY_REFERENCE, GetCompanyReferenceData);
  yield takeLatest(REQUEST_COMPANY_JWT, CompanyJWTTokenData);
  yield takeLatest(REQUEST_COMPANY_PACKAGE, GetPackageData);
  yield takeLatest(REQUEST_COMPANY_CREDITE, GetCrediteData);
  yield takeLatest(REQUEST_COMPANY_GET_CV_LIST, GetCVListData);

  yield takeLatest(REQUEST_GET_PRODUCTS, GetProductsData);
}
