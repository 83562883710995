import React from 'react'

function ToggleButtonControl(props) {

    const {
        CreatAd_One,
        setCreatAd_One,
        errorCreatAd_One,
        setErrorCreatAd_One,
        mystyle
    } = props;

    return (
        <>
            <div className="col-12 mt-40 mt-sm-5">
                <div className="switch-btn-wrap">
                    <div
                        className="switch-btn"
                        onClick={() => {
                            setCreatAd_One((prevstate) => ({
                                ...prevstate,
                                allow_phone_contact:
                                    !CreatAd_One?.allow_phone_contact,
                            }));
                            CreatAd_One?.allow_phone_contact === true &&
                                setErrorCreatAd_One((preverr) => ({
                                    ...preverr,
                                    allow_phone_contact: "",
                                }));
                            if (CreatAd_One?.allow_phone_contact === false) {
                                setCreatAd_One((prevstate) => ({
                                    ...prevstate,
                                    phone: "+39",
                                }));
                            }

                        }}
                    >
                        <input
                            type="checkbox"
                            readOnly
                            checked={CreatAd_One.allow_phone_contact}
                        />
                        <span></span>
                    </div>
                    <label>
                        Voglio che il referente possa essere contattato per
                        telefono
                    </label>
                </div>
                {CreatAd_One.allow_phone_contact === false &&
                    errorCreatAd_One?.allow_phone_contact && (
                        <div style={mystyle}>
                            {errorCreatAd_One.allow_phone_contact}
                        </div>
                    )}
            </div>

            <div className="col-12">
                <div className="switch-btn-wrap">
                    <div
                        className="switch-btn"
                        onClick={() => {
                            setCreatAd_One((prevstate) => ({
                                ...prevstate,
                                allow_e_mail_contact:
                                    !CreatAd_One?.allow_e_mail_contact,
                            }));
                            CreatAd_One?.allow_e_mail_contact === true &&
                                setErrorCreatAd_One((preverr) => ({
                                    ...preverr,
                                    allow_e_mail_contact: "",
                                }));
                            if (CreatAd_One?.allow_e_mail_contact === false) {
                                setCreatAd_One((prevstate) => ({
                                    ...prevstate,
                                    addemail: "",
                                }));
                            }
                        }}
                    >
                        <input
                            type="checkbox"
                            readOnly
                            checked={CreatAd_One.allow_e_mail_contact}
                        />
                        <span></span>
                    </div>
                    <label>
                        Voglio che il referente possa essere contattato per
                        e-mail
                    </label>
                </div>
                {CreatAd_One?.allow_e_mail_contact === false &&
                    errorCreatAd_One?.allow_e_mail_contact && (
                        <div style={mystyle}>
                            {errorCreatAd_One?.allow_e_mail_contact}
                        </div>
                    )}
            </div>

            <div className="col-12">
                <div className="switch-btn-wrap">
                    <div
                        className="switch-btn"
                        onClick={() => {
                            setCreatAd_One((prevstate) => ({
                                ...prevstate,
                                is_exp_date: !CreatAd_One?.is_exp_date,
                            }));
                            CreatAd_One?.is_exp_date === true &&
                                setErrorCreatAd_One((preverr) => ({
                                    ...preverr,
                                    is_exp_date: "",
                                }));
                            if (CreatAd_One?.is_exp_date === false) {
                                setCreatAd_One((prevstate) => ({
                                    ...prevstate,
                                    ad_exp_date: "",
                                }));
                            }
                        }}
                    >
                        <input
                            type="checkbox"
                            readOnly
                            checked={CreatAd_One.is_exp_date}
                        />
                        <span></span>
                    </div>
                    <label>
                        Voglio fissare un termine per l’invio delle
                        candidature
                    </label>
                </div>
                {CreatAd_One?.is_exp_date === false &&
                    errorCreatAd_One?.is_exp_date && (
                        <div style={mystyle}>
                            {errorCreatAd_One?.is_exp_date}
                        </div>
                    )}
            </div>
        </>
    )
}

export default ToggleButtonControl