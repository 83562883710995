import React from 'react';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import BackButton from '../common/BackButton';

function CreaAnnuncioSeven(props) {
    const navigate = useNavigate();
    const {
        prevStep,
        CreatAd_Seven,
        errorCreatAd_Seven,
        handleOnCreatAd_Seven,
        validateAdSponsorDate,
        mystyle,
        t
    } = props

    const redirectToPackage = () => {
        navigate("/company/PackageToken")
    }

    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        <div className="seekng_job_title_note">
                            <div className="seekng_job_title">
                                <h2>
                                    Inserisci un nuovo annuncio per la ricerca di dipendenti
                                </h2>
                                <h6>Pubblica annuncio</h6>
                            </div>
                        </div>
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <div className="publish_block">
                                                <div className="publish_block_inner">
                                                    <div className="publish_text">
                                                        <p>
                                                            Pubblica il tuo annuncio sponsorizzandolo. <br />
                                                            In questo modo l annuncio risulterà molto più
                                                            visibile ai potenziali candidati interessati
                                                        </p>
                                                        <p>
                                                            Ti suggeriamo un valore di sponsorizzazione che,
                                                            in base alla nostre statistiche storiche può far
                                                            aumentare la percentuale di candidature
                                                            interessate fino ad un valore dell 84%
                                                        </p>
                                                    </div>

                                                    <div className="row g-3">
                                                        <div className="col-md-6 col-12">
                                                            <label className="form-label">
                                                                Crediti da investire
                                                            </label>
                                                            <div className="d-flex flex-wrap flex-sm-nowrap contact-duration">
                                                                <input className="form-control" />
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Giorno"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12 text-center">
                                                            <label className="form-label">
                                                                Sponsorizza fino al
                                                            </label>
                                                            <div className="d-flex flex-wrap flex-sm-nowrap contact-duration justify-content-center">
                                                                <input
                                                                    className={`form-control w-auto ${errorCreatAd_Seven.sponsorDate ? "error" : ""
                                                                        }`}
                                                                    type="date"
                                                                    name="sponsorDate"
                                                                    id="sponsorDate"
                                                                    value={CreatAd_Seven?.sponsorDate || ""}
                                                                    onChange={(e) => handleOnCreatAd_Seven(e)}
                                                                    onBlur={validateAdSponsorDate}
                                                                    placeholder="Sponsorizza fino al"
                                                                />
                                                                {errorCreatAd_Seven.sponsorDate && (
                                                                    <div style={mystyle}>
                                                                        {errorCreatAd_Seven.sponsorDate}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <p className="credits_text">
                                Crediti spesi al giorno:
                                <span>Non hai crediti sufficienti!</span>
                            </p>
                            <div className="btn btn_default btn_green_outline" onClick={() => redirectToPackage()}>
                                acquista crediti
                            </div>
                            <div className="btn btn_default btn_green">
                                Sponsorizza annuncio
                            </div>
                        </div>

                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <div className="publish_block">
                                                <div className="publish_block_inner">
                                                    <div className="publish_text mb-0">
                                                        <p>
                                                            Pubblica il tuo annuncio senza alcuna
                                                            sponsorizzazione.
                                                        </p>
                                                        <p>
                                                            L’annuncio sarà visibile ad un numero limitato di
                                                            potenziali candidati interessati
                                                        </p>
                                                        <p>
                                                            Se le candidature interessate che ti
                                                            raggiungeranno non ti soddisferanno potrai in ogni
                                                            momento procedere ad una sponsorizzazione
                                                            dell’annuncio aumentandone in modo considerevole
                                                            la visibilità e di conseguenza il numero di
                                                            potenziali candidati che potranno rendersi
                                                            disponibili
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <div className="btn btn_default btn_orange_outline">
                                pubblica senza sponsorizzare
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CreaAnnuncioSeven;