import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestGetList,
    requestGetEducationLevelList,
    requestGetJobList,
    requestGetJobListUserId,
    requestGetrecTime,
    requestGetContractTypes,
    requestGetAvailabilities,
    requestGetAdditionalWages,
    requestGetBenefits,
    requestCreateAdvertisement,
    requestCompanyDetails,
    requestCompanyUpdate,
    userLogout
} from "../../Redux/actions";
import CreaAnnuncioOne from "./step1/CreaAnnuncioOne";
import CreaAnnuncioTwo from "./step2/CreaAnnuncioTwo";
import CreaAnnuncioThree from "./step3/CreaAnnuncioThree";
import CreaAnnuncioFour from "./step4/CreaAnnuncioFour";
import CreaAnnuncioFive from "./step5/CreaAnnuncioFive";
import CreaAnnuncioSix from "./step6/CreaAnnuncioSix";
import CreaAnnuncioSeven from "./step7/CreaAnnuncioSeven";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function FinalForm(props) {
    const [countryData, setCountryData] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [step, setStep] = useState(() => {
        const savedStep = localStorage.getItem("CreaAnnuncio");
        return savedStep ? Number(savedStep) : 1;
    });


    const isClearable = true;
    const isSearchable = true;
    const matchFromStart = true;

    const filterConfig = {
        matchFrom: matchFromStart ? "start" : "any",
    };

    const { t } = useTranslation();

    const [error, setError] = useState(false);

    //========================== step-1 State ==========================

    const [CreatAd_One, setCreatAd_One] = useState({
        country: "Italia",
        province: "",
        city: "",
        street: "",
        zip_code: "",
        cellphone: "+39",
        email_for_updates: "",
        e_mail: "",
        job_title: "",
        updates_by_email: false,
        allow_phone_contact: true,
        allow_e_mail_contact: true,
        is_exp_date: true,
        isAddressComplete: true,
        n_roles_sought: "",
        application_sending_deadline: "",
        recruitment_time: "",
        privious_ad: "",
        contact_person: "",
    });

    const [errorCreatAd_One, setErrorCreatAd_One] = useState({
        country: "",
        province: "",
        city: "",
        street: "",
        zip_code: "",
        cellphone: "",
        email_for_updates: "",
        e_mail: "",
        job_title: "",
        updates_by_email: "",
        allow_phone_contact: "",
        allow_e_mail_contact: "",
        is_exp_date: "",
        isAddressComplete: true,

        n_roles_sought: "",
        application_sending_deadline: "",
        recruitment_time: "",
        privious_ad: "",
        contact_person: "",
    });

    const previousAddRef = useRef(null);
    const countryRef = useRef(null);
    const cityRef = useRef(null);
    const provinceRef = useRef(null);
    const streetRef = useRef(null);
    const emailRef = useRef(null);
    const addemailRef = useRef(null);
    const phoneRef = useRef(null);

    const addressRef = useRef(null);
    const birthDateRef = useRef(null);

    const [selectedCity, setSelectedCity] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [recruitmentTimeline, setRecruitmentTimeline] = useState([]);

    const [jobList, setJobList] = useState([])

    const [previousAdd, setPreviousAdd] = useState()

    const [companyId, setCompanyId] = useState(null)

    const [representative, setRepresentative] = useState([])

    //========================== step-2 State==========================

    const [CreatAd_Two, setCreatAd_Two] = useState({
        educationLevels: "",
        driverlicense: [],
        job_starting_date: "",
        isStartDateVisible: true,
    });

    const [errorCreatAd_Two, setErrorCreatAd_Two] = useState({
        educationLevels: "",
        subeducationLevels: "",
        driverlicense: "",
        job_starting_date: "",
        isStartDateVisible: true,
    });

    const [educationLevels, setEducationLevels] = useState([]);
    const [subSubmenuOptions, setSubSubmenuOptions] = useState([]);

    const [driverlicenseData, setDriverlicenseData] = useState([]);

    const [selectedOptionsDrivelicense, setSelectedOptionsDrivelicense] = useState([]);

    const educationLevelRef = useRef(null);

    const subEducationalRef = useRef(null);

    const driverLicenseRef = useRef(null);

    const [selectedOptionsEducation, setSelectedOptionsEducation] = useState({
        submenu: "",
        subSubmenu: "",
    });

    //========================== step-3 State ==========================

    const positionErrorRef = useRef(null);
    const fileErrorRef = useRef(null);
    const descErrorRef = useRef(null);
    const [CreatAd_Three, setCreatAd_Three] = useState({
        job_title: "",
        selectedFile: null,
        desc: "",
    });

    const [errorCreatAd_Three, setErrorCreatAd_Three] = useState({
        job_title: "",
        selectedFile: null,
        desc: "",
    });

    //========================== step-4 State ==========================

    const contractTypeRef = useRef(null);
    const durationRef = useRef(null);
    const durationStringRef = useRef(null);
    const availabilityRef = useRef(null);

    const [CreatAd_Four, setCreatAd_Four] = useState({
        contract_type: [],
        contract_duration_n: "",
        contract_duration_period: "",
        availability: [],
    });

    const [errorCreatAd_Four, setErrorCreatAd_Four] = useState({
        contract_type: "",
        contract_duration_n: "",
        contract_duration_period: "",
        availability: "",
    });

    const [contractTypesData, setContractTypesData] = useState([])
    const [availabilitiesData, setAvailabilitiesData] = useState([])

    const [selectedOptionsContractType, setSelectedOptionsContractType] = useState([]);


    const [selectedOptionsAvailabilityType, setSelectedOptionsAvailabilityType] = useState([]);

    //========================== step-5  State==========================

    const [CreatAd_Five, setCreatAd_Five] = useState({
        fixedAmount: "",
        salary_recurrence: "",
        minAmount: "",
        maxAmount: "",
        additional_wages: [],
        benefit: [],
        range: false
    });

    const [errorCreatAd_Five, setErrorCreatAd_Five] = useState({
        additional_wages: "",
        benefit: "",
        fixedAmount: "",
        salary_recurrence: "",
        minAmount: "",
        maxAmount: "",
        range: ""
    });

    const [additionalWagesData, setAdditionalWagesData] = useState([])
    const [benefitsData, setBenefitsData] = useState([])


    const [selectedOptionsAdditionalWages, setSelectedOptionsAdditionalWages] = useState([]);

    const [selectedOptionsBenefits, setSelectedOptionsBenefits] = useState([]);

    //========================== step-6 State ==========================

    const [CreatAd_Six, setCreatAd_Six] = useState(
        [
            {
                question: ""
            }
        ]
    );

    const [errorCreatAd_Six, setErrorCreatAd_Six] = useState([
        {
            question: ""
        }
    ]);

    const formFocusQuestionRefs = {
        question: useRef([]),
    };

    //========================== step-7 State ==========================

    const [CreatAd_Seven, setCreatAd_Seven] = useState({ sponsorDate: "" });

    const [errorCreatAd_Seven, setErrorCreatAd_Seven] = useState({ sponsorDate: "" });

    const mystyle = {
        color: "#D10000",
        padding: "3px 10px",
        width: "100%",
    };


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            props.requestGetJobList({ token });
            props.requestGetrecTime({ token });
            props.requestGetContractTypes({ token });
            props.requestGetAvailabilities({ token });
            props.requestGetAdditionalWages({ token });
            props.requestGetBenefits({ token });
            props.requestCompanyDetails({ data: { token } })
        }
        props.requestGetList();
        props.requestGetEducationLevelList();
    }, []);

    useEffect(() => {
        const getCompanyData = props.company.getCompanyData;
        if (getCompanyData !== undefined) {
            if (getCompanyData.data?.Status === "Success") {
                const CompanyDetails = getCompanyData?.data?.Message || {};
                const representativeData = CompanyDetails?.representative || [];
                console.log(CompanyDetails, "CompanyDetails")

                setCreatAd_One((prevState) => ({
                    ...prevState,
                    country: CompanyDetails.address?.[0]?.country || "",
                    street: CompanyDetails.address?.[0]?.street || "",
                    city: CompanyDetails.address?.[0]?.city || "",
                    province: CompanyDetails.address?.[0]?.province || "",
                    zip_code: CompanyDetails.address?.[0]?.zip_code || "",
                    e_mail: CompanyDetails.e_mail || "",
                    updates_by_email: CompanyDetails.e_mail_verified,
                }));

                const transformedRepresentativeData = representativeData?.map((item, index) => ({
                    value: index,
                    label: item.e_mail,
                })) || [];

                setRepresentative(transformedRepresentativeData);

                if (CompanyDetails.flag_company) {
                    setCompanyId(CompanyDetails?.id);


                } else {
                    // setCompanyId()
                }
            } else {
                props.company.getCompanyData = undefined;
            }
        } else {
            props.company.getCompanyData = undefined;
        }

    }, [props.company.getCompanyData])

    useEffect(() => {
        if (companyId) {
            const token = localStorage.getItem("token");
            const requestData = {
                company_id: companyId,
            };

            if (token) {
                props.requestGetJobListUserId({ data: requestData, token });
            }
        }
    }, [companyId]);

    useEffect(() => {
        let levelData = props.candidate.getlistData;
        if (levelData !== undefined) {
            if (levelData?.data?.Status === "Success") {
                const processedCountries = levelData.data.Message.countries.map(
                    (item) => {
                        const name = item.nativeCountryName;
                        return name;
                    }
                );
                const uniqueCountries = [...new Set(processedCountries)];
                const uniqueCountryData = uniqueCountries.map((name) => ({
                    nativeCountryName: name,
                }));

                setCountryData(uniqueCountryData);

                const municipalit = levelData?.data?.Message?.municipalities;
                const uniqueMunicipalities = [];
                const municipalityNames = new Set();

                municipalit.forEach((municipality) => {
                    if (!municipalityNames.has(municipality.name)) {
                        municipalityNames.add(municipality.name);
                        const municipalityObject = {
                            name: `${municipality.name}, ${municipality.provincia.name}`,
                        };
                        uniqueMunicipalities.push(municipalityObject);
                    }
                });

                const transformedCityData =
                    uniqueMunicipalities?.map((city, index) => ({
                        value: index,
                        label: city?.name,
                    })) || [];

                setMunicipalities(transformedCityData);

                const transformedDriverLicenseData =
                    levelData.data.Message.drivingLicences.map((license) => ({
                        value: license.id,
                        label: license.licenceType,
                    }));
                setDriverlicenseData(transformedDriverLicenseData);
            }
        }
    }, [props.candidate.getlistData]);

    const sortedCountryData = [...countryData].sort((a, b) => {
        if (a.nativeCountryName === "Italia") return -1;
        if (b.nativeCountryName === "Italia") return 1;
        return 0;
    });


    useEffect(() => {
        let GetJobListData = props.candidate.GetJobListData;

        if (GetJobListData && GetJobListData?.data.Status === "Success") {
            const transformedJoblistData = GetJobListData?.data?.Message?.map((job, index) => ({
                value: index,
                label: job.job_title,
            })) || [];
            setJobList(transformedJoblistData);
        }
    }, [props.candidate.GetJobListData])

    useEffect(() => {
        const {
            getRecTime,
            getContractTypes,
            getAvailabilities,
            getAdditionalWages,
            getBenefits,
        } = props.company;

        if (getRecTime && getRecTime.data?.Status === "Success") {
            const transformedRecruitmentTimeline = getRecTime.data.Message?.map((time, index) => ({
                value: index,
                label: time.time,
            })) || [];
            setRecruitmentTimeline(transformedRecruitmentTimeline);
        }

        if (getContractTypes && getContractTypes.data?.Status === "Success") {
            const transformedContractTypesData = getContractTypes.data.Message?.map((contract, index) => ({
                value: index,
                label: contract.contract_name,
            })) || [];
            setContractTypesData(transformedContractTypesData);
        }

        if (getAvailabilities && getAvailabilities.data?.Status === "Success") {
            const transformedAvailabilitiesData = getAvailabilities.data.Message?.map((availability, index) => ({
                value: index,
                label: availability.availability_type,
            })) || [];
            setAvailabilitiesData(transformedAvailabilitiesData);
        }

        if (getAdditionalWages && getAdditionalWages.data?.Status === "Success") {
            const transformedAdditionalWagesData = getAdditionalWages.data.Message?.map((additional, index) => ({
                value: index,
                label: additional.additional_wage,
            })) || [];
            setAdditionalWagesData(transformedAdditionalWagesData);
        }

        if (getBenefits && getBenefits.data?.Status === "Success") {
            const transformedBenefitsData = getBenefits.data.Message?.map((benefit, index) => ({
                value: index,
                label: benefit.benefit,
            })) || [];
            setBenefitsData(transformedBenefitsData);
        }
    }, [
        props.company.getRecTime,
        props.company.getContractTypes,
        props.company.getAvailabilities,
        props.company.getAdditionalWages,
        props.company.getBenefits,

    ]);

    // console.log("CreatAd_OneCreatAd_OneCreatAd_One", CreatAd_One)
    //get job list user id wise

    useEffect(() => {
        const jobListUserIdData = props?.candidate?.GetJobListUserId;
        if (jobListUserIdData !== undefined) {
            const jobListUserIdDetails = jobListUserIdData?.data?.Message || []
            const transformedjobListUserIdDetails = jobListUserIdDetails?.map((item, index) => ({
                value: index,
                label: item.job_title,
            })) || [];

            setPreviousAdd(transformedjobListUserIdDetails);
        } else {
            props.candidate.GetJobListUserId = undefined;
        }

    }, [props.candidate.GetJobListUserId])


    //get education leve data 

    useEffect(() => {
        const educationlevelData = props.candidate.EducationlevelData;
        if (educationlevelData?.data?.Status === "Success") {
            const uniqueLevels = {};

            educationlevelData.data.Message.forEach(level => {
                if (!uniqueLevels[level.levelType]) {
                    uniqueLevels[level.levelType] = { value: level.id, label: level.levelType };
                }
            });
            const transformedEducationlevelData = Object.values(uniqueLevels);
            setEducationLevels(transformedEducationlevelData);

            const filteredSubMenuOptions = educationlevelData.data.Message
                .filter(level => level.specialisation)
                .map(level => ({ levelType: level.levelType, specialisation: level.specialisation }));

            setSubSubmenuOptions(filteredSubMenuOptions);
        }
    }, [props.candidate.EducationlevelData]);

    //=====================================STEP 1 Validateion  ==========================================

    // const proviousaddvalidate = () => {
    //     const priviousAd = CreatAd_One?.privious_ad;
    //     if (!priviousAd) {
    //         setErrorCreatAd_One((prev) => ({
    //             ...prev,
    //             privious_ad: "previous ad required must",
    //         }));
    //         return false;
    //     } else {
    //         setErrorCreatAd_One((prevState) => ({
    //             ...prevState,
    //             privious_ad: "",
    //         }));

    //         return true;
    //     }
    // };

    const proviousaddvalidate = () => {
        const priviousAd = CreatAd_One?.privious_ad;
        if (!priviousAd) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                privious_ad: "previous ad required must",
            }));
            return false;
        }
        setErrorCreatAd_One((prev) => ({ ...prev, privious_ad: "" }));
        return true
    };

    const validateJobTitle = () => {
        const jobTitle = CreatAd_One?.job_title;

        if (!jobTitle) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                job_title: "Seleziona una posizione ricercata.",
            }));
            return false;
        }

        setErrorCreatAd_One((prev) => ({ ...prev, job_title: "" }));
        return true;
    };

    const contactpersonValidate = () => {
        const priviousAd = CreatAd_One?.contact_person;
        if (!priviousAd) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                contact_person: "internal person required must",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                contact_person: "",
            }));

            return true;
        }
    };
    const validateEmail = () => {
        const email = emailRef.current.value;
        if (!email) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                email_for_updates: "Inserisci la tua e-mail.",
            }));
            return false;
        }
        const trimmedEmail = email.trim();
        if (!trimmedEmail) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                email_for_updates: "L'e-mail non può essere vuota.",
            }));
            return false;
        }
        if (
            !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                email_for_updates: "Inserisci un ID e-mail valido.",
            }));
            return false;
        }
        setErrorCreatAd_One((prev) => ({
            ...prev,
            email_for_updates: "",
        }));
        return true;
    };

    const validateAddEmail = () => {
        const email = addemailRef.current.value;
        if (!email) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                e_mail: "Inserisci la tua e-mail.",
            }));
            return false;
        }
        const trimmedEmail = email.trim();
        if (!trimmedEmail) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                e_mail: "L'e-mail non può essere vuota.",
            }));
            return false;
        }
        if (
            !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                e_mail: "Inserisci un ID e-mail valido.",
            }));
            return false;
        }
        setErrorCreatAd_One((prev) => ({ ...prev, e_mail: "" }));
        return true;
    };

    function validateZipCode() {
        const zip_code = CreatAd_One.zip_code;

        if (!zip_code) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                zip_code: "Inserisci il tuo Cap.",
            }));
            return false;
        }

        const trimmedZipcode = zip_code.trim();

        if (!trimmedZipcode) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                zip_code: "Il CAP non può essere vuoto.",
            }));
            return false;
        }

        if (!/^\d+$/.test(trimmedZipcode)) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                zip_code: "Il CAP può contenere solo numeri.",
            }));
            return false;
        }

        if (trimmedZipcode.length < 5) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                zip_code: "Il CAP deve contenere almeno 5 cifre.",
            }));
            return false;
        }

        setErrorCreatAd_One((prevState) => ({
            ...prevState,
            zip_code: "",
        }));

        return true;
    }

    const validateCountry = () => {
        const { country } = CreatAd_One;
        if (!country) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                country: "Nazione non selezionato.",
            }));
            return false;
        }

        setErrorCreatAd_One((prevState) => ({
            ...prevState,
            country: "",
        }));
        return true;
    };

    const validateCity = () => {
        let city;

        if (CreatAd_One?.country === "Italia") {
            if (CreatAd_One !== "undefined") {
                if (!CreatAd_One?.city || !CreatAd_One?.province) {
                    setErrorCreatAd_One((prev) => ({
                        ...prev,
                        city: "Comune non selezionato.",
                    }));
                    return false;
                }
            }
        } else {
            if (cityRef?.current) {
                city = cityRef.current.value ?? "";
                if (!city.trim()) {
                    setErrorCreatAd_One((prev) => ({
                        ...prev,
                        city: "Inserisci la tua Comune.",
                    }));
                    return false;
                }
            }
        }
        setErrorCreatAd_One((prev) => ({
            ...prev,
            city: "",
        }));
        return true;
    };

    function validateProvince() {
        if (provinceRef?.current !== null) {
            const province = provinceRef?.current?.value;
            if (!province) {
                setErrorCreatAd_One((prev) => ({
                    ...prev,
                    province: "Inserisci la tua provincia.",
                }));
                return false;
            } else {
                setErrorCreatAd_One((prev) => ({
                    ...prev,
                    province: " ",
                }));
                return true;
            }
        } else {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                province: "",
            }));
            return true;
        }
    }

    const validatePhone = () => {
        let phone_no = phoneRef?.current?.props.value;
        if (!phone_no) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                cellphone: "Inserisci il numero di telefono.",
            }));

            return false;
        } else if (phone_no.length < 5) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                cellphone: "Inserisci il numero di telefono.",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                cellphone: "",
            }));
            return true;
        }
    };



    const validateNumberOfRoles = () => {
        const numberOfRoles = CreatAd_One?.n_roles_sought;
        if (!numberOfRoles) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                n_roles_sought: "Il numero di ruoli ricercati non può essere vuoto.",
            }));
            return false;
        }
        if (isNaN(numberOfRoles) || numberOfRoles <= 0) {
            setErrorCreatAd_One((prev) => ({
                ...prev,
                n_roles_sought: "Inserisci un numero valido di ruoli ricercati.",
            }));
            return false;
        }
        setErrorCreatAd_One((prev) => ({
            ...prev,
            error_n_roles_sought: "",
        }));
        return true;
    };

    function validateStreetNumber() {
        const street_number = CreatAd_One?.street;

        if (!street_number) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                street: "Inserisci il numero civico.",
            }));
            return false;
        }

        if (street_number === "") {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                street: "Il numero civico non può essere vuoto.",
            }));
            return false;
        }

        setErrorCreatAd_One((prevErrors) => ({
            ...prevErrors,
            street: "",
        }));
        return true;
    }

    const validateAdExpDate = () => {
        const { application_sending_deadline } = CreatAd_One;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!application_sending_deadline) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                application_sending_deadline: "Inserisci una data di scadenza valida.",
            }));
            return false;
        } else if (new Date(application_sending_deadline).getTime() >= today.getTime()) {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                application_sending_deadline: "Inserisci una data di scadenza valida.",
            }));

            return false;
        } else {
            setErrorCreatAd_One((prevState) => ({
                ...prevState,
                application_sending_deadline: "",
            }));
            return true;
        }
    };

    const validateTimelinePosition = () => {
        const recruitment_time = CreatAd_One?.recruitment_time
        if (!recruitment_time) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                recruitment_time: "Recruitment timeline cannot be empty",
            }));
            return false;
        }
        setErrorCreatAd_One((prevErrors) => ({
            ...prevErrors,
            recruitment_time: "",
        }));
        return true;
    };
    const mobilToggle = () => {
        if (CreatAd_One?.allow_phone_contact === false) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                allow_phone_contact: "Mobile number toggle true must required must",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                allow_phone_contact: "",
            }));
            return true;
        }
    };
    const emailToggle = () => {
        if (CreatAd_One?.allow_e_mail_contact === false) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                allow_e_mail_contact: "email toggle true must required must",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                allow_e_mail_contact: "",
            }));
            return true;
        }
    };
    const dateToggle = () => {
        if (CreatAd_One?.is_exp_date === false) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                is_exp_date: "date toggle true must required must",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                is_exp_date: "",
            }));
            return true;
        }
    };
    const emailVerificationToggle = () => {
        if (CreatAd_One?.updates_by_email === false) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                updates_by_email: "email verification toggle true must required must",
            }));
            return false;
        } else {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                updates_by_email: "",
            }));
            return true;
        }
    };
    const isAddress = () => {
        if (CreatAd_One?.isAddressComplete === false) {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                isAddressComplete: "Address toggle true must required must",
            }));
            return false;
        }
        else {
            setErrorCreatAd_One((prevErrors) => ({
                ...prevErrors,
                isAddressComplete: "",
            }));
            return true;
        }
    };
    //=====================================STEP 2 Validateion  ==========================================

    const validateEducationLevel = () => {
        const educationLevelsData = CreatAd_Two?.educationLevels;
        if (!educationLevelsData) {
            setErrorCreatAd_Two((prev) => ({
                ...prev,
                educationLevels: "Seleziona il titolo dell'annuncio.",
            }));
            return false;
        }
        setErrorCreatAd_Two((prev) => ({ ...prev, educationLevels: "" }));
        return true;
    };

    const validateSubEducationLevel = () => {
        if (subEducationalRef?.current) {
            const subEducationalData = subEducationalRef?.current?.value;
            if (!subEducationalData) {
                setErrorCreatAd_Two((prev) => ({
                    ...prev,
                    subeducationLevels: "Seleziona il titolo dell'annuncio.",
                }));
                return false;
            }
        }
        setErrorCreatAd_Two((prev) => ({
            ...prev,
            subeducationLevels: "",
        }));
        return true;
    };

    const validateDateTwo = () => {
        const job_starting_dateData = CreatAd_Two?.job_starting_date;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (!job_starting_dateData) {
            setErrorCreatAd_Two((prev) => ({
                ...prev,
                job_starting_date: "Inserisci una data valida.",
            }));
            return false;
        }
        if (new Date(job_starting_dateData).getTime() >= today.getTime()) {
            setErrorCreatAd_Two((prev) => ({
                ...prev,
                job_starting_date: "La data di iscrizione non può essere futura.",
            }));
            return false;
        }
        setErrorCreatAd_Two((prev) => ((prev) => ({ ...prev, job_starting_date: "" })));
        return true;
    };

    const periodDateToggle = () => {
        if (CreatAd_Two?.isStartDateVisible === false) {
            setErrorCreatAd_Two((prevErrors) => ({
                ...prevErrors,
                isStartDateVisible: "date toggle true must required must",
            }));
            return false;
        } else {
            setErrorCreatAd_Two((prevErrors) => ({
                ...prevErrors,
                isStartDateVisible: "",
            }));
            return true;
        }
    };

    const validateCreateTwoDriverlicense = () => {
        if (selectedOptionsDrivelicense?.length === 0) {
            setErrorCreatAd_Two((prevErrors) => ({
                ...prevErrors,
                driverlicense: "Seleziona almeno una patente di guida.",
            }));
            return false;
        } else {
            setErrorCreatAd_Two((prevErrors) => ({
                ...prevErrors,
                driverlicense: "",
            }));
            return true;
        }
    };

    //=====================================STEP 3 Validateion  ==========================================

    const validateFormthirdJobTitle = () => {
        const { job_title } = CreatAd_Three;
        if (!job_title) {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                job_title: "Please select at least one option.",
            }));
            return false
        } else {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                job_title: "",
            }));
            return true
        }
    };

    const validatethirdFormFile = () => {
        const { selectedFile } = CreatAd_Three;
        if (!selectedFile) {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                selectedFile: "Il file è obbligatorio",
            }));
            return false
        } else {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                selectedFile: null,
            }));
            return true
        }
    }

    const validateFormThirdCkeditor = () => {
        const { desc } = CreatAd_Three;
        if (!desc) {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                desc: "La descrizione è obbligatoria",
            }));
            return false
        } else {
            setErrorCreatAd_Three((prevErrors) => ({
                ...prevErrors,
                desc: "",
            }));
            return true
        }
    }

    //=====================================STEP 4 Validateion  ==========================================

    const validateContractType = () => {
        if (selectedOptionsContractType?.length === 0) {
            setErrorCreatAd_Four((prevErrors) => ({
                ...prevErrors,
                contract_type: "Il tipo di contratto è obbligatorio",
            }));
            return false;
        } else {
            setErrorCreatAd_Four((prevErrors) => ({
                ...prevErrors,
                contract_type: "",
            }));
            return true;
        }
    };

    const validateAvailability = () => {
        if (selectedOptionsAvailabilityType?.length === 0) {
            setErrorCreatAd_Four((prevErrors) => ({
                ...prevErrors,
                availability: "La disponibilità è obbligatoria",
            }));
            return false;
        } else {
            setErrorCreatAd_Four((prevErrors) => ({
                ...prevErrors,
                availability: "",
            }));
            return true;
        }
    };

    const validateDuration = () => {
        const { contract_duration_n, contract_duration_period } = CreatAd_Four;

        if (contract_duration_n) {
            if (!/^\d+$/.test(contract_duration_n)) {
                setErrorCreatAd_Four((prevErrors) => ({
                    ...prevErrors,
                    contract_duration_n: "La durata deve essere un numero intero",
                }));
                return false;
            }
        }

        if (contract_duration_n || contract_duration_period) {
            if (!contract_duration_n && contract_duration_period) {
                setErrorCreatAd_Four((prevErrors) => ({
                    ...prevErrors,
                    contract_duration_n:
                        "Selezionare anche la durata se si sceglie una durata del contratto",
                }));
                return false;
            }

            if (!contract_duration_period && contract_duration_n) {
                setErrorCreatAd_Four((prevErrors) => ({
                    ...prevErrors,
                    contract_duration_period:
                        "Se si inserisce una durata, selezionare anche la durata del contratto",
                }));
                return false;
            }
        }

        setErrorCreatAd_Four((prevErrors) => ({
            ...prevErrors,
            contract_duration_n: "",
            contract_duration_period: "",
        }));

        return true;
    };

    //=====================================STEP 5 Validateion  ==========================================

    const validateForFixAmount = () => {
        const { fixedAmount } = CreatAd_Five;
        if (CreatAd_Five?.range === false) {
            if (!fixedAmount) {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    fixedAmount: "This field is required.",
                }));
                return false
            } else {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    fixedAmount: "",
                }));
                return true
            }
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                fixedAmount: "",
            }));
            return true
        }

    };

    const validateForSalaryRecurrence = () => {
        const { salary_recurrence } = CreatAd_Five;
        if (!salary_recurrence) {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                salary_recurrence: "This field is required.",
            }));
            return false
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                salary_recurrence: "",
            }));
            return true
        }
    };

    const validateForMinAmount = () => {
        const { minAmount } = CreatAd_Five;
        if (CreatAd_Five?.range === true) {
            if (!minAmount) {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    minAmount: "This field is required.",
                }));
                return false
            } else {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    minAmount: "",
                }));
                return true
            }
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                minAmount: "",
            }));
            return true
        }
    };

    const validateForMaxAmount = () => {
        const { maxAmount } = CreatAd_Five;

        if (CreatAd_Five?.range === true) {
            if (!maxAmount) {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    maxAmount: "This field is required.",
                }));
                return false
            } else {
                setErrorCreatAd_Five((prevErrors) => ({
                    ...prevErrors,
                    maxAmount: "",
                }));
                return true
            }
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                maxAmount: "",
            }));
            return true
        }
    };


    const validateAdditionalWages = () => {
        if (selectedOptionsAdditionalWages?.length === 0) {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                additional_wages: "Il tipo di contratto è obbligatorio",
            }));
            return false;
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                additional_wages: "",
            }));
            return true;
        }
    };

    const validateBenefit = () => {
        if (selectedOptionsBenefits?.length === 0) {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                benefit: "Il tipo di contratto è obbligatorio",
            }));
            return false;
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                benefit: "",
            }));
            return true;
        }
    };

    //=====================================STEP 6 Validateion  ==========================================

    function validateFormSixQuestion(i) {
        let formIsValid = true;

        const questionsFilled = formFocusQuestionRefs?.question?.current[i]?.question?.value;

        if (!questionsFilled) {
            formIsValid = false;
            const updateErrorQuestions = [...errorCreatAd_Six];
            updateErrorQuestions[i].question = "Inserisci il tuo nome.";
            setErrorCreatAd_Six(updateErrorQuestions);
        }

        const trimmedQuestions = questionsFilled.trim();

        if (formIsValid && !trimmedQuestions) {
            formIsValid = false;
            const updateErrorQuestions = [...errorCreatAd_Six];
            updateErrorQuestions[i].question = "Il nome non può essere vuoto.";
            setErrorCreatAd_Six(updateErrorQuestions);
        }

        if (formIsValid) {
            const updateErrorQuestions = [...errorCreatAd_Six];
            updateErrorQuestions[i].question = "";
            setErrorCreatAd_Six(updateErrorQuestions);
        }

        return formIsValid;
    }

    //=====================================STEP 7 Validateion  ==========================================


    const validateAdSponsorDate = () => {
        const { sponsorDate } = CreatAd_Seven;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!sponsorDate) {
            setErrorCreatAd_Seven((prevState) => ({
                ...prevState,
                sponsorDate: "Inserisci una data di scadenza valida.",
            }));
            return false;
        } else if (new Date(sponsorDate).getTime() >= today.getTime()) {
            setErrorCreatAd_Seven((prevState) => ({
                ...prevState,
                sponsorDate: "Inserisci una data di scadenza valida.",
            }));

            return false;
        } else {
            setErrorCreatAd_Seven((prevState) => ({
                ...prevState,
                sponsorDate: "",
            }));
            return true;
        }
    };

    //=====================================STEP 1 Function==========================================

    const handleOnCreatAd_One = (e) => {
        const { name, value, type } = e.target;
        let newValue;

        if (type === "number") {
            newValue = value.replace(/\D/g, "").slice(0, 5);
        } else {
            newValue = value;
        }

        if (name === "country") {
            if (value === "Italia") {
                setCreatAd_One((prevData) => ({
                    ...prevData,
                    country: newValue,
                    city: "",
                    province: "",
                }));
            } else {
                setCreatAd_One((prevData) => ({
                    ...prevData,
                    country: newValue,
                    city: "",
                    province: "",
                }));
            }
        } else {
            setCreatAd_One((prevData) => ({ ...prevData, [name]: newValue }));
        }
    };

    const handleChangeCityCreatAd_One = async (selectedOptions) => {
        setIsLoading(true);
        if (selectedOptions !== null) {
            setTimeout(() => {
                const [city, province] = selectedOptions?.label.split(", ");
                setCreatAd_One((prevData) => ({
                    ...prevData,
                    city: city,
                    province: province,
                }));
                setSelectedCity(selectedOptions);
                setIsLoading(false);
                setErrorCreatAd_One((prevState) => ({
                    ...prevState,
                    city: "",
                }));
            }, 1000);
        } else {
            setCreatAd_One((prevData) => ({
                ...prevData,
                city: "",
                province: "",
            }));
            setSelectedCity(null);
            setIsLoading(false);
        }
    };

    const handleOnDadeLineDate = (event) => {
        const expireDate = event.target.value;
        if (expireDate.length < 11) {
            setCreatAd_One({ ...CreatAd_One, application_sending_deadline: expireDate });
        }
    };

    const handlePhoneChange = (value) => {
        if (typeof value !== "undefined") {
            if (!value.startsWith("+")) {
                value = "+" + value;
            }
            setCreatAd_One((prevData) => ({ ...prevData, cellphone: value }));
        }
    };


    //======================================== STEP 2 Function ==============================|

    const handleOnCreatAd_Two = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setCreatAd_Two((prevData) => ({ ...prevData, [name]: value ? value : "" }))
    };

    const handleOnCreatAdTwoEducationLevel = (e) => {
        setIsLoading(true);
        if (e !== null) {
            setTimeout(() => {
                const updatedSelectedOptions = e ? e.label : "";
                setCreatAd_Two((prevData) => ({ ...prevData, educationLevels: updatedSelectedOptions }));
                setSelectedOptionsEducation((prevOptions) => ({
                    ...prevOptions,
                    submenu: updatedSelectedOptions,
                    subSubmenu: "",
                }));
                setIsLoading(false);
                setErrorCreatAd_Two((prev) => ({ ...prev, educationLevels: "" }));
            }, 50);
        } else {
            setCreatAd_Two((prevData) => ({ ...prevData, educationLevels: "" }));
            setSelectedOptionsEducation((prevOptions) => ({
                ...prevOptions,
                submenu: "",
                subSubmenu: "",
            }));
            setIsLoading(false);
            validateEducationLevel()
        }
    };

    const handleCreatAdTwoSubEducation = (e) => {
        const { value } = e.target;

        setSelectedOptionsEducation((prevOptions) => {
            const newOptions = {
                ...prevOptions,
                subSubmenu: value,
            };

            const educationLevels = `${newOptions.submenu}, ${value}`;
            setCreatAd_Two((prevData) => ({
                ...prevData,
                educationLevels,
            }));

            return newOptions;
        });
    };

    const handleCreatTwoDriver = (selectedOptions) => {
        const licenseTypes = selectedOptions?.map((option) => ({ licenceType: option.label })) || [];
        setCreatAd_Two((prevData) => ({
            ...prevData,
            driverlicense: licenseTypes,
        }));
        setSelectedOptionsDrivelicense(selectedOptions);
        setErrorCreatAd_Two((prevErrors) => ({
            ...prevErrors,
            driverlicense: "",
        }));
    };

    useEffect(() => {
        validateSubEducationLevel();
    }, [selectedOptionsEducation]);

    //======================================== STEP 3 Function ==============================

    const handleOnPostionTitle = (selectOption) => {
        if (selectOption !== null) {
            const newData = selectOption.label ? selectOption?.label : ""
            setCreatAd_Three((prevData) => ({ ...prevData, position: newData }));
        } else {
            setCreatAd_Three((prevData) => ({ ...prevData, position: "" }));
        }
    }

    const handleOnCreatAdThird = (e) => {
        const { name, value, type, files } = e.target;
        const newValue = type === "file" ? files[0] : value;

        setCreatAd_Three((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    //===========================================  STEP 4 Function ============================================

    const handleCreateFourType = (selectedOptions) => {
        const contractType = selectedOptions?.map((option) => ({ contract_name: option.label })) || [];
        setCreatAd_Four((prevData) => ({
            ...prevData,
            contract_type: contractType,
        }));
        setSelectedOptionsContractType(selectedOptions);
        setErrorCreatAd_Four((prevErrors) => ({
            ...prevErrors,
            contract_type: "",
        }));
    };

    const handleCreateAvailability = (selectedOptions) => {
        const availabilityType = selectedOptions?.map((option) => ({ availability_type: option.label })) || [];
        setCreatAd_Four((prevData) => ({
            ...prevData,
            availability: availabilityType,
        }));
        setSelectedOptionsAvailabilityType(selectedOptions);
        setErrorCreatAd_Four((prevErrors) => ({
            ...prevErrors,
            availability: "",
        }));
    };

    const handleContractDuration = (selectedOptions) => {
        if (selectedOptions !== null) {
            setCreatAd_Four((prevData) => ({ ...prevData, contract_duration_period: selectedOptions.label ? selectedOptions.label : "" }))
        }
    };


    const handleOnCreatAd_Four = (e) => {
        const { name, value } = e.target;
        setCreatAd_Four((prevData) => ({ ...prevData, [name]: value ? value : "" }))
    };

    //=================================================Step 5 Function============================================

    const handleInputChange = (event, inputName) => {
        const value = event?.target?.value.replace(/[^0-9]/g, "");
        setCreatAd_Five((prevData) => ({
            ...prevData,
            [inputName]: value,
        }));
    };

    const handleCreateFiveAdditionalWages = (selectedOptions) => {
        const additionalWages = selectedOptions?.map((option) => ({ additional_wages: option.label })) || [];
        setCreatAd_Five((prevData) => ({
            ...prevData,
            additional_wages: additionalWages,
        }));
        setSelectedOptionsAdditionalWages(selectedOptions);
        setErrorCreatAd_Five((prevErrors) => ({
            ...prevErrors,
            additional_wages: "",
        }));
    };

    const handleCreateFiveBenefits = (selectedOptions) => {
        const benefitType = selectedOptions?.map((option) => ({ benefit: option.label })) || [];
        setCreatAd_Five((prevData) => ({
            ...prevData,
            benefit: benefitType,
        }));
        setSelectedOptionsBenefits(selectedOptions);
        setErrorCreatAd_Five((prevErrors) => ({
            ...prevErrors,
            benefit: "",
        }));
    };

    //============================================= Step 6  Function================================================

    const handleOnAddQuestions = () => {
        let length = CreatAd_Six.length;
        if (length === 0) {
            setCreatAd_Six([
                ...CreatAd_Six,
                {
                    question: ""
                }
            ]);

            setErrorCreatAd_Six([
                ...errorCreatAd_Six,
                {
                    question: "",
                }
            ]);
        } else if (
            CreatAd_Six[length - 1].question === ""
        ) {
            Swal.fire(
                "Errore!",
                "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
                "error"
            );
            return;
        } else {
            setCreatAd_Six([
                ...CreatAd_Six,
                {
                    question: "",
                }
            ]);
            setErrorCreatAd_Six([
                ...errorCreatAd_Six,
                {
                    question: "",
                }
            ]);
        }
    };

    const handleOnCreatAdSixQuestion = (e, i) => {
        let { name, value } = e.target;
        const newList = [...CreatAd_Six];
        newList[i][name] = value;
        setCreatAd_Six(newList);
    };

    const handleRemoveQuestions = (index) => {
        const updateQuestions = [...CreatAd_Six];
        updateQuestions.splice(index, 1);
        setCreatAd_Six(updateQuestions);

        const updateErrorQuestions = [...errorCreatAd_Six];
        updateErrorQuestions.splice(index, 1);
        setErrorCreatAd_Six(updateErrorQuestions);
    }

    //============================================= Step 7  Function================================================
    const handleOnCreatAd_Seven = (e) => {
        const { name, value } = e.target;
        setCreatAd_Seven((prevData) => ({ ...prevData, [name]: value }));
    }

    function validateFormFirst() {
        let countryV = validateCountry();
        let cityV = validateCity();
        let provinceV = validateProvince();
        let streetNum = validateStreetNumber();
        let zipCode = validateZipCode();
        let add_email = validateAddEmail();
        let phonN = validatePhone();
        let expbirthV = validateAdExpDate();
        let privious_ad = proviousaddvalidate();
        let contactperson = contactpersonValidate();
        let jobtitle = validateJobTitle();
        let datetoggle = dateToggle();
        let mobiletoggle = mobilToggle();
        let emailtoggle = emailToggle();
        let timeline = validateTimelinePosition();
        let emailverify = emailVerificationToggle();
        let validatemail = validateEmail();
        let validaterole = validateNumberOfRoles();
        const addresstoggle = isAddress();
        const validall =
            countryV &&
            cityV &&
            provinceV &&
            streetNum &&
            zipCode &&
            add_email &&
            phonN &&
            expbirthV &&
            privious_ad &&
            contactperson &&
            jobtitle &&
            datetoggle &&
            mobiletoggle &&
            emailtoggle &&
            timeline &&
            emailverify &&
            validatemail &&
            validaterole &&
            addresstoggle;
        return validall;
    }

    function validateFormAddress() {
        let countryV = validateCountry();
        let cityV = validateCity();
        let provinceV = validateProvince();
        let streetNum = validateStreetNumber();
        let zipCode = validateZipCode();
        let add_email = validateAddEmail();

        const validall =
            countryV &&
            cityV &&
            provinceV &&
            streetNum &&
            zipCode &&
            add_email
        return validall;
    }

    function validateFormSecond() {
        let educationlevelValid = validateEducationLevel();
        let subEducationLevelValid = validateSubEducationLevel()
        let driverlicenseValid = validateCreateTwoDriverlicense();
        let datevalid = validateDateTwo()
        let dateToggle = periodDateToggle()
        const valid =
            educationlevelValid &&
            subEducationLevelValid &&
            driverlicenseValid &&
            datevalid &&
            dateToggle
        return valid
    }

    function validateFormThird() {
        let job_titleV = validateFormthirdJobTitle();
        let fileV = validatethirdFormFile();
        let ckeditorV = validateFormThirdCkeditor();
        const validall =
            job_titleV &&
            fileV &&
            ckeditorV
        return validall;
    }

    function validateFormFour() {
        let durationValid = validateDuration();
        let availabilityValid = validateAvailability();
        let contractTypeValid = validateContractType();
        const validall =
            durationValid &&
            availabilityValid &&
            contractTypeValid
        return validall;
    }

    function validateFormFive() {
        let fixedAmountValid = validateForFixAmount();
        let salaryRecurrenceValid = validateForSalaryRecurrence();
        let minAmountValid = validateForMinAmount();
        let maxAmountValid = validateForMaxAmount();
        let additionalWagesValid = validateAdditionalWages();
        let benefiltsValid = validateBenefit();
        const validall =
            fixedAmountValid &&
            salaryRecurrenceValid &&
            minAmountValid &&
            maxAmountValid &&
            additionalWagesValid &&
            benefiltsValid
        return validall;
    }

    const validateFormSix = () => {
        if (CreatAd_Six.length > 0) {
            return CreatAd_Six.every((_, i) => validateFormSixQuestion(i));
        }
        return false;
    };

    console.log(CreatAd_One, "CreatAd_One")

    //address update 

    const handleOnGetAddress = (e) => {
        e.preventDefault();
        props.userLogout();
        console.log("address get details", e);
        if (validateFormAddress()) {
            const token = localStorage.getItem("token");

            props.requestCompanyUpdate({
                data: {
                    e_mail: CreatAd_One.company_email,
                    address: {
                        street: CreatAd_One.street,
                        city: CreatAd_One.city,
                        zip_code: CreatAd_One.zip_code,
                        state: null,
                        province: CreatAd_One.province,
                        country: CreatAd_One.country,
                        email_for_updates: CreatAd_One.e_mail_verified,
                    },
                },
                token: token
            })

            setError(false);
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        let companyUpdateData = props.company.companyUpdateData;
        if (companyUpdateData !== undefined) {
            if (companyUpdateData.data.Status === "Success") {
                toast.success(companyUpdateData.data.Status, { theme: "colored", autoClose: 1000 });
                props.company.companyUpdateData = undefined;
            } else {
                toast.error(companyUpdateData.data.Message, { theme: "colored", autoClose: 1000 });
                setError(true)
                props.company.companyUpdateData = undefined;
            }
        }
    }, [props.company.companyUpdateData]);


    const handleNextStep = (formType) => {
        const token = localStorage.getItem("token");
        let isValid = false;
        switch (formType) {
            case "first":
                isValid = validateFormFirst();
                if (isValid) {
                    console.log(CreatAd_One, "final first Object");
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            address: {
                                country: CreatAd_One.country,
                                city: CreatAd_One.city,
                                province: CreatAd_One.province,
                                // n_roles_sought:     CreatAd_One  
                                street: CreatAd_One.street,
                                zip_code: CreatAd_One.zip_code
                            },
                            cellphone: CreatAd_One.cellphone,
                            application_sending_deadline: CreatAd_One.application_sending_deadline,
                            e_mail: CreatAd_One.e_mail,
                            n_roles_sought: CreatAd_One.n_roles_sought,
                            allow_e_mail_contact: CreatAd_One.allow_e_mail_contact,
                            allow_phone_contact: CreatAd_One.allow_phone_contact,
                            email_for_updates: CreatAd_One.email_for_updates,
                            is_exp_date: CreatAd_One.is_exp_date,
                            job_title: CreatAd_One.job_title,
                            privious_ad: CreatAd_One.privious_ad,
                            recruitment_time: {
                                time: CreatAd_One.recruitment_time
                            },
                            updates_by_email: CreatAd_One.updates_by_email,
                            contact_person: CreatAd_One.contact_person
                        },
                    });
                }
                break;
            case 'second':
                isValid = validateFormSecond();
                if (isValid) {
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            driverlicense: CreatAd_Two.driverlicense,
                            educationLevels: CreatAd_Two.educationLevels,
                            job_starting_date: CreatAd_Two.job_starting_date
                        },
                    });
                }
                break;
            case 'third':
                isValid = validateFormThird();
                if (isValid) {
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            description: CreatAd_Three.desc,
                            picture: CreatAd_Three.selectedFile,
                            job_title: CreatAd_Three.job_title
                        },
                    });
                }
                break;
            case 'four':
                isValid = validateFormFour();
                if (isValid) {
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            availability: CreatAd_Four.availability,
                            contract_duration_n: CreatAd_Four.contract_duration_n,
                            contract_duration_period: CreatAd_Four.contract_duration_period,
                            contract_type: CreatAd_Four.contract_type
                        },
                    });
                }
                break;
            case 'five':
                isValid = validateFormFive();
                if (isValid) {
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            salary: {
                                range: CreatAd_Five.range,
                                fixedAmount: CreatAd_Five.fixedAmount,
                                additional_wages: CreatAd_Five.additional_wages,
                                benefit: CreatAd_Five.benefit,
                                reccurency: CreatAd_Five.salary_recurrence,
                                minAmount: CreatAd_Five.minAmount,
                                maxAmount: CreatAd_Five.maxAmount
                            }
                        },
                    });
                }
                break;
            case 'six':
                isValid = validateFormSix();
                if (isValid) {
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            questions: CreatAd_Six
                        },
                    });
                }
                break;
            case 'seven':
                isValid = validateAdSponsorDate();
                if (isValid) {
                    console.log(CreatAd_Seven, "final Seven Object");
                    props.requestCreateAdvertisement({
                        token: token,
                        data: {
                            CreatAd_Seven: CreatAd_Seven
                        },
                    });
                }
                break;
            default:
                break;
        }

        if (isValid) {
            const nextStep = step + 1;
            // setStep(nextStep);
            // localStorage.setItem('CreaAnnuncio', nextStep);
            // window.scrollTo({ top: 0, behavior: 'smooth' });

            try {

                const advertisementData = props.company.advertisementData;
                console.log(advertisementData, "advertisementData response***************************")
                if (advertisementData?.data?.Status === "Success") {
                    toast.success(advertisementData?.data?.Message, { theme: "colored", autoClose: 1000 });
                    setStep(nextStep);
                    localStorage.setItem('currentStep', nextStep);
                } else {
                    toast.error(advertisementData?.data?.Message, { theme: "colored", autoClose: 1000 });
                }
                // props.company.advertisementData = undefined;
                setError(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

            } catch (error) {
                console.error('Error occurred while calling API:', error);
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    const prevStep = () => {
        const previousStep = step - 1;
        setStep(previousStep);
        localStorage.setItem('CreaAnnuncio', previousStep);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderStepComponent = () => {
        switch (step) {
            case 1:
                return (
                    <CreaAnnuncioOne
                        nextStep={() => handleNextStep("first")}
                        CreatAd_One={CreatAd_One}
                        errorCreatAd_One={errorCreatAd_One}
                        setCreatAd_One={setCreatAd_One}
                        setErrorCreatAd_One={setErrorCreatAd_One}
                        sortedCountryData={sortedCountryData}
                        municipalities={municipalities}
                        recruitmentTimeline={recruitmentTimeline}
                        countryRef={countryRef}
                        provinceRef={provinceRef}
                        emailRef={emailRef}
                        addemailRef={addemailRef}
                        cityRef={cityRef}
                        phoneRef={phoneRef}
                        streetRef={streetRef}
                        handleOnCreatAd_One={handleOnCreatAd_One}
                        handleChangeCityCreatAd_One={handleChangeCityCreatAd_One}
                        handleOnDadeLineDate={handleOnDadeLineDate}
                        handlePhoneChange={handlePhoneChange}
                        handleOnGetAddress={handleOnGetAddress}
                        isLoading={isLoading}
                        validateEmail={validateEmail}
                        validateCountry={validateCountry}
                        validateCity={validateCity}
                        validateProvince={validateProvince}
                        validateZipCode={validateZipCode}
                        validatePhone={validatePhone}
                        validateAddEmail={validateAddEmail}
                        validateJobTitle={validateJobTitle}
                        validateNumberOfRoles={validateNumberOfRoles}
                        validateStreetNumber={validateStreetNumber}
                        validateAdExpDate={validateAdExpDate}
                        validateTimelinePosition={validateTimelinePosition}
                        proviousaddvalidate={proviousaddvalidate}
                        contactpersonValidate={contactpersonValidate}
                        error={error}
                        setError={setError}
                        mystyle={mystyle}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        filterConfig={filterConfig}
                        t={t}
                        jobList={jobList}
                        previousAdd={previousAdd}
                        representative={representative}
                        previousAddRef={previousAddRef}
                    />
                );
            case 2:
                return (
                    <CreaAnnuncioTwo
                        nextStep={() => handleNextStep("second")}
                        prevStep={prevStep}
                        CreatAd_Two={CreatAd_Two}
                        errorCreatAd_Two={errorCreatAd_Two}
                        setCreatAd_Two={setCreatAd_Two}
                        setErrorCreatAd_Two={setErrorCreatAd_Two}
                        educationLevels={educationLevels}
                        subSubmenuOptions={subSubmenuOptions}
                        driverlicenseData={driverlicenseData}
                        handleOnCreatAd_Two={handleOnCreatAd_Two}
                        validateDateTwo={validateDateTwo}
                        birthDateRef={birthDateRef}
                        addressRef={addressRef}
                        isClearable={isClearable}
                        mystyle={mystyle}
                        t={t}
                        selectedOptionsEducation={selectedOptionsEducation}
                        educationLevelRef={educationLevelRef}
                        validateEducationLevel={validateEducationLevel}
                        handleOnCreatAdTwoEducationLevel={handleOnCreatAdTwoEducationLevel}
                        handleCreatAdTwoSubEducation={handleCreatAdTwoSubEducation}
                        validateSubEducationLevel={validateSubEducationLevel}
                        subEducationalRef={subEducationalRef}
                        isLoading={isLoading}

                        handleCreatTwoDriver={handleCreatTwoDriver}
                        validateCreateTwoDriverlicense={validateCreateTwoDriverlicense}
                        driverLicenseRef={driverLicenseRef}
                        selectedOptionsDrivelicense={selectedOptionsDrivelicense}
                    />
                );
            case 3:
                return (
                    <CreaAnnuncioThree
                        nextStep={() => handleNextStep("third")}
                        prevStep={prevStep}
                        CreatAd_Three={CreatAd_Three}
                        setCreatAd_Three={setCreatAd_Three}
                        validateFormthirdJobTitle={validateFormthirdJobTitle}
                        errorCreatAd_Three={errorCreatAd_Three}
                        handleOnCreatAdThird={handleOnCreatAdThird}
                        handleOnPostionTitle={handleOnPostionTitle}
                        validatethirdFormFile={validatethirdFormFile}
                        validateFormThirdCkeditor={validateFormThirdCkeditor}
                        positionErrorRef={positionErrorRef}
                        fileErrorRef={fileErrorRef}
                        descErrorRef={descErrorRef}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        filterConfig={filterConfig}
                        mystyle={mystyle}
                        t={t}
                        jobList={jobList}
                    />
                );
            case 4:
                return (
                    <CreaAnnuncioFour
                        nextStep={() => handleNextStep("four")}
                        prevStep={prevStep}
                        CreatAd_Four={CreatAd_Four}
                        errorCreatAd_Four={errorCreatAd_Four}
                        contractTypesData={contractTypesData}
                        availabilitiesData={availabilitiesData}
                        filterConfig={filterConfig}
                        validateDuration={validateDuration}
                        validateAvailability={validateAvailability}
                        validateContractType={validateContractType}
                        contractTypeRef={contractTypeRef}
                        durationRef={durationRef}
                        durationStringRef={durationRef}
                        availabilityRef={availabilityRef}
                        mystyle={mystyle}
                        t={t}
                        handleCreateFourType={handleCreateFourType}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        selectedOptionsAvailabilityType={selectedOptionsAvailabilityType}
                        handleCreateAvailability={handleCreateAvailability}
                        selectedOptionsContractType={selectedOptionsContractType}
                        handleOnCreatAd_Four={handleOnCreatAd_Four}
                        handleContractDuration={handleContractDuration}
                    />
                );
            case 5:
                return (
                    <CreaAnnuncioFive
                        nextStep={() => handleNextStep("five")}
                        prevStep={prevStep}
                        CreatAd_Five={CreatAd_Five}
                        setCreatAd_Five={setCreatAd_Five}
                        errorCreatAd_Five={errorCreatAd_Five}
                        additionalWagesData={additionalWagesData}
                        benefitsData={benefitsData}
                        handleInputChange={handleInputChange}
                        filterConfig={filterConfig}
                        mystyle={mystyle}
                        t={t}
                        setErrorCreatAd_Five={setErrorCreatAd_Five}
                        handleCreateFiveAdditionalWages={handleCreateFiveAdditionalWages}
                        handleCreateFiveBenefits={handleCreateFiveBenefits}
                        validateForFixAmount={validateForFixAmount}
                        validateForSalaryRecurrence={validateForSalaryRecurrence}
                        validateForMinAmount={validateForMinAmount}
                        validateForMaxAmount={validateForMaxAmount}
                        validateAdditionalWages={validateAdditionalWages}
                        validateBenefit={validateBenefit}
                        selectedOptionsBenefits={selectedOptionsBenefits}
                        selectedOptionsAdditionalWages={selectedOptionsAdditionalWages}
                    />
                );
            case 6:
                return (
                    <CreaAnnuncioSix
                        nextStep={() => handleNextStep("six")}
                        prevStep={prevStep}
                        CreatAd_Six={CreatAd_Six}
                        errorCreatAd_Six={errorCreatAd_Six}
                        handleOnAddQuestions={handleOnAddQuestions}
                        handleRemoveQuestions={handleRemoveQuestions}
                        handleOnCreatAdSixQuestion={handleOnCreatAdSixQuestion}
                        validateFormSixQuestion={validateFormSixQuestion}
                        formFocusQuestionRefs={formFocusQuestionRefs}
                        mystyle={mystyle}
                        t={t}
                    />
                );
            case 7:
                return (
                    <CreaAnnuncioSeven
                        prevStep={prevStep}
                        CreatAd_Seven={CreatAd_Seven}
                        errorCreatAd_Seven={errorCreatAd_Seven}
                        handleOnCreatAd_Seven={handleOnCreatAd_Seven}
                        validateAdSponsorDate={validateAdSponsorDate}
                        mystyle={mystyle}
                        t={t}
                    />
                );
            default:
                return null;
        }
    };

    return renderStepComponent();
}

const mapStateToProps = (state) => {
    return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestGetList,
        requestGetEducationLevelList,
        requestGetJobList,
        requestGetJobListUserId,
        requestGetrecTime,
        requestGetContractTypes,
        requestGetAvailabilities,
        requestGetAdditionalWages,
        requestGetBenefits,
        requestCreateAdvertisement,
        requestCompanyDetails,
        requestCompanyUpdate,
        userLogout
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinalForm);
