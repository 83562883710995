//register
export const REQUEST_REGISTER = "REQUEST_REGISTER";
export const RECEIVE_REGISTER = "RECEIVE_REGISTER";
export const requestRegister = (obj) => ({ type: REQUEST_REGISTER, obj });
export const receiveRegister = (data) => ({ type: RECEIVE_REGISTER, data });

//login
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const requestLogin = (obj) => ({ type: REQUEST_LOGIN, obj });
export const receiveLogin = (data) => ({ type: RECEIVE_LOGIN, data });

//===============refresh token ==================================

//refresh login
// export const REQUEST_REFRESH_LOGIN = "REQUEST_REFRESH_LOGIN";
// export const RECEIVE_REFRESH_LOGIN = "RECEIVE_REFRESH_LOGIN";
// export const requestRefreshLogin = (obj) => ({ type: REQUEST_REFRESH_LOGIN, obj });
// export const receiveRefreshLogin = (data) => ({ type: RECEIVE_REFRESH_LOGIN, data });

//get list
export const REQUEST_GET_LIST = "REQUEST_GET_LIST";
export const RECEIVE_GET_LIST = "RECEIVE_GET_LIST";
export const requestGetList = (obj) => ({ type: REQUEST_GET_LIST, obj });
export const receiveGetList = (data) => ({ type: RECEIVE_GET_LIST, data });

//get location
export const REQUEST_GET_LOCATION = "REQUEST_GET_LOCATION";
export const RECEIVE_GET_LOCATION = "RECEIVE_GET_LOCATION";
export const requestGetLocation = (obj) => ({
  type: REQUEST_GET_LOCATION,
  obj,
});
export const receiveGetLocation = (data) => ({
  type: RECEIVE_GET_LOCATION,
  data,
});

//get regione list
export const REQUEST_GET_REGIONE_LIST = "REQUEST_GET_REGIONE_LIST";
export const RECEIVE_GET_REGIONE_LIST = "RECEIVE_GET_REGIONE_LIST";
export const requestGetRegioneList = (obj) => ({
  type: REQUEST_GET_REGIONE_LIST,
  obj,
});
export const receiveGetRegioneList = (data) => ({
  type: RECEIVE_GET_REGIONE_LIST,
  data,
});

//get education level list
export const REQUEST_GET_EDUCATION_LEVEL_LIST =
  "REQUEST_GET_EDUCATION_LEVEL_LIST";
export const RECEIVE_GET_EDUCATION_LEVEL_LIST =
  "RECEIVE_GET_EDUCATION_LEVEL_LIST";
export const requestGetEducationLevelList = (obj) => ({
  type: REQUEST_GET_EDUCATION_LEVEL_LIST,
  obj,
});
export const receiveGetEducationLevelList = (data) => ({
  type: RECEIVE_GET_EDUCATION_LEVEL_LIST,
  data,
});

//get sector level  list
export const REQUEST_GET_SECTOR_LIST = "REQUEST_GET_SECTOR_LIST";
export const RECEIVE_GET_SECTOR_LIST = "RECEIVE_GET_SECTOR_LIST";
export const requestGetSectorList = (obj) => ({
  type: REQUEST_GET_SECTOR_LIST,
  obj,
});
export const receiveGetSectorsList = (data) => ({
  type: RECEIVE_GET_SECTOR_LIST,
  data,
});

//get candidate details
export const REQUEST_GET_CANDIDATE = "REQUEST_GET_CANDIDATE";
export const RECEIVE_GET_CANDIDATE = "RECEIVE_GET_CANDIDATE";
export const requestGetCandidate = (obj) => ({
  type: REQUEST_GET_CANDIDATE,
  obj,
});
export const receiveGetCandidate = (data) => ({
  type: RECEIVE_GET_CANDIDATE,
  data,
});

//candidate Profile Update
export const REQUEST_CANDIDATE_PROFILE = "REQUEST_CANDIDATE_PROFILE";
export const RECEIVE_CANDIDATE_PROFILE = "RECEIVE_CANDIDATE_PROFILE";
export const requestCandidateProfile = (obj) => ({
  type: REQUEST_CANDIDATE_PROFILE,
  obj,
});
export const receiveCandidateProfile = (data) => ({
  type: RECEIVE_CANDIDATE_PROFILE,
  data,
});

//candidate resume
export const REQUEST_CANDIDATE_RESUME = "REQUEST_CANDIDATE_RESUME";
export const RECEIVE_CANDIDATE_RESUME = "RECEIVE_CANDIDATE_RESUME";
export const requestCandidateResume = (obj) => ({
  type: REQUEST_CANDIDATE_RESUME,
  obj,
});
export const receiveCandidateResume = (data) => ({
  type: RECEIVE_CANDIDATE_RESUME,
  data,
});

//get resume details
export const REQUEST_GET_RESUME = "REQUEST_GET_RESUME";
export const RECEIVE_GET_RESUME = "RECEIVE_GET_RESUME";
export const requestGetResumeDetails = (obj) => ({
  type: REQUEST_GET_RESUME,
  obj,
});
export const receiveGetResumeDetails = (data) => ({
  type: RECEIVE_GET_RESUME,
  data,
});

//update resume details
export const REQUEST_UPDATE_RESUME = "REQUEST_UPDATE_RESUME";
export const RECEIVE_UPDATE_RESUME = "RECEIVE_UPDATE_RESUME";
export const requestUpdateResumeDetails = (obj) => ({
  type: REQUEST_UPDATE_RESUME,
  obj,
});
export const receiveUpdateResumeDetails = (data) => ({
  type: RECEIVE_UPDATE_RESUME,
  data,
});

//delete resume details
export const REQUEST_DELETE_RESUME_FILE = "REQUEST_DELETE_RESUME_FILE";
export const RECEIVE_DELETE_RESUME_FILE = "RECEIVE_DELETE_RESUME_FILE";
export const requestDeleteResumeFile = (obj) => ({
  type: REQUEST_DELETE_RESUME_FILE,
  obj,
});
export const receiveDeleteResumeFile = (data) => ({
  type: RECEIVE_DELETE_RESUME_FILE,
  data,
});

//delete reccomdation letter
export const REQUEST_DELETE_LETTER_FILE = "REQUEST_DELETE_LETTER_FILE ";
export const RECEIVE_DELETE_LETTER_FILE = "RECEIVE_DELETE_LETTER_FILE ";
export const requestDeleteLetterFile = (obj) => ({
  type: REQUEST_DELETE_LETTER_FILE,
  obj,
});
export const receiveDeleteLetterFile = (data) => ({
  type: RECEIVE_DELETE_LETTER_FILE,
  data,
});

//get job details
export const REQUEST_GET_JOB = "REQUEST_GET_JOB";
export const RECEIVE_GET_JOB = "RECEIVE_GET_JOB";
export const requestGetJobDetails = (obj) => ({ type: REQUEST_GET_JOB, obj });
export const receiveGetJobDetails = (data) => ({ type: RECEIVE_GET_JOB, data });

//get job listing
export const REQUEST_GET_JOBLIST = "REQUEST_GET_JOBLIST";
export const RECEIVE_GET_JOBLIST = "RECEIVE_GET_JOBLIST";
export const requestGetJobList = (obj) => ({ type: REQUEST_GET_JOBLIST, obj });
export const receiveGetJobList = (data) => ({
  type: RECEIVE_GET_JOBLIST,
  data,
});
export const RESET_GET_JOBLIST = "RESET_GET_JOBLIST";
export const resetGetJobList = () => ({ type: RESET_GET_JOBLIST });

export const REQUEST_GET_JOBLIST_USER_ID = "REQUEST_GET_JOBLIST_USER_ID";
export const RECEIVE_GET_JOBLIST_USER_ID = "RECEIVE_GET_JOBLIST_USER_ID";

export const REQUEST_GET_REC_TIME = "REQUEST_GET_REC_TIME";
export const requestGetrecTime = () => ({ type: REQUEST_GET_REC_TIME });

export const REQUEST_GET_CONTRACT_TYPES = "REQUEST_GET_CONTRACT_TYPES";
export const requestGetContractTypes = () => ({
  type: REQUEST_GET_CONTRACT_TYPES,
});

export const REQUEST_GET_AVAILABILITIES = "REQUEST_GET_AVAILABILITIES";
export const requestGetAvailabilities = () => ({
  type: REQUEST_GET_AVAILABILITIES,
});

export const REQUEST_GET_ADDITIONAL_WAGES = "REQUEST_GET_ADDITIONAL_WAGES";
export const requestGetAdditionalWages = () => ({
  type: REQUEST_GET_ADDITIONAL_WAGES,
});

export const REQUEST_GET_BENEFITS = "REQUEST_GET_BENEFITS";
export const requestGetBenefits = () => ({ type: REQUEST_GET_BENEFITS });

export const REQUEST_CREATE_ADVERTISEMENT = "REQUEST_CREATE_ADVERTISEMENT";
export const requestCreateAdvertisement = () => ({
  type: REQUEST_CREATE_ADVERTISEMENT,
});

export const requestGetJobListUserId = (obj) => ({
  type: REQUEST_GET_JOBLIST_USER_ID,
  obj,
});
export const receiveGetJobListUserId = (data) => ({
  type: RECEIVE_GET_JOBLIST_USER_ID,
  data,
});

//==================================== Company ===================================

//register
export const REQUEST_COMPANY_REGISTER = "REQUEST_COMPANY_REGISTER";
export const RECEIVE_COMPANY_REGISTER = "RECEIVE_COMPANY_REGISTER";
export const requestCompanyRegister = (obj) => ({
  type: REQUEST_COMPANY_REGISTER,
  obj,
});
export const receiveCompanyRegister = (data) => ({
  type: RECEIVE_COMPANY_REGISTER,
  data,
});

//get company register
export const REQUEST_COMPANY_DETAILS = "REQUEST_COMPANY_DETAILS ";
export const RECEIVE_COMPANY_DETAILS = "RECEIVE_COMPANY_DETAILS ";
export const requestCompanyDetails = (obj) => ({
  type: REQUEST_COMPANY_DETAILS,
  obj,
});
export const receiveCompanyDetails = (data) => ({
  type: RECEIVE_COMPANY_DETAILS,
  data,
});

//update company register
export const REQUEST_COMPANY_UPDATE = "REQUEST_COMPANY_UPDATE";
export const RECEIVE_COMPANY_UPDATE = "RECEIVE_COMPANY_UPDATE";
export const requestCompanyUpdate = (obj) => ({
  type: REQUEST_COMPANY_UPDATE,
  obj,
});
export const receiveCompanyUpdate = (data) => ({
  type: RECEIVE_COMPANY_UPDATE,
  data,
});

// company jwt token
export const REQUEST_COMPANY_JWT = "REQUEST_COMPANY_JWT";
export const RECEIVE_COMPANY_JWT = "RECEIVE_COMPANY_JWT";
export const requestCompanyJWT = (obj) => ({ type: REQUEST_COMPANY_JWT, obj });
export const receiveCompanyJWT = (data) => ({
  type: RECEIVE_COMPANY_JWT,
  data,
});

//company reference
export const REQUEST_COMPANY_REFERENCE = "REQUEST_COMPANY_REFERENCE";
export const RECEIVE_COMPANY_REFERENCE = "RECEIVE_COMPANY_REFERENCE";
export const requestCompanyReference = (obj) => ({
  type: REQUEST_COMPANY_REFERENCE,
  obj,
});
export const receiveCompanyReference = (data) => ({
  type: RECEIVE_COMPANY_REFERENCE,
  data,
});

//package token
export const REQUEST_COMPANY_PACKAGE = "REQUEST_COMPANY_PACKAGE";
export const RECEIVE_COMPANY_PACKAGE = "RECEIVE_COMPANY_PACKAGE";
export const requestCompanyPackage = (obj) => ({
  type: REQUEST_COMPANY_PACKAGE,
  obj,
});
export const receiveCompanyPackage = (data) => ({
  type: RECEIVE_COMPANY_PACKAGE,
  data,
});

//Credite token
export const REQUEST_COMPANY_CREDITE = "REQUEST_COMPANY_CREDITE";
export const RECEIVE_COMPANY_CREDITE = "RECEIVE_COMPANY_CREDITE";
export const requestCompanyCredite = (obj) => ({
  type: REQUEST_COMPANY_CREDITE,
  obj,
});
export const receiveCompanyCredite = (data) => ({
  type: RECEIVE_COMPANY_CREDITE,
  data,
});

//CV Finder
export const REQUEST_COMPANY_GET_CV_LIST = "REQUEST_COMPANY_GET_CV_LIST";
export const RECEIVE_COMPANY_GET_CV_LIST = "RECEIVE_COMPANY_GET_CV_LIST";
export const requestGetCVList = (obj) => ({
  type: REQUEST_COMPANY_GET_CV_LIST,
  obj,
});
export const receiveGetCVList = (data) => ({
  type: RECEIVE_COMPANY_GET_CV_LIST,
  data,
});

//logout
export const USER_LOGOUT = "USER_LOGOUT";
export const userLogout = () => ({ type: USER_LOGOUT });

// ==================================== Agent ===================================

//opportunity finder
export const REQUEST_AGENT_OPPORTUNITY_FINDER =
  "REQUEST_AGENT_OPPORTUNITY_FINDER";
export const RECEIVE_AGENT_OPPORTUNITY_FINDER =
  "RECEIVE_AGENT_OPPORTUNITY_FINDER";
export const requestAgentOpportunityFinder = (obj) => ({
  type: REQUEST_AGENT_OPPORTUNITY_FINDER,
  obj,
});
export const receiveAgentOpportunityFinder = (data) => ({
  type: RECEIVE_AGENT_OPPORTUNITY_FINDER,
  data,
});

export const REQUEST_GET_PRODUCTS = "REQUEST_GET_PRODUCTS";
export const RECEIVE_GET_PRODUCTS = "RECEIVE_GET_PRODUCTS";
export const requestGetProducts = (obj) => ({
  type: REQUEST_GET_PRODUCTS,
  obj,
});
export const receiveGetProducts = (data) => ({
  type: RECEIVE_GET_PRODUCTS,
  data,
});
