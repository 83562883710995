// File: /src/Redux/agentReducer.js
// Created Date: Tuesday September 10th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday September 10th 2024 1:14:25 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import {
  RECEIVE_GET_JOB,
  RECEIVE_GET_JOBLIST,
  RECEIVE_GET_LIST,
  RECEIVE_GET_LOCATION,
  RECEIVE_GET_SECTOR_LIST,
  RECEIVE_LOGIN,
  RECEIVE_REGISTER,
  RESET_GET_JOBLIST,
  REQUEST_GET_CANDIDATE,
  RECEIVE_GET_CANDIDATE,
  RECEIVE_GET_PRODUCTS,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REGISTER:
      return {
        ...state,
        registerData: data,
      };

    case RECEIVE_LOGIN:
      return {
        ...state,
        loginData: data,
      };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_GET_LOCATION:
      return {
        ...state,
        getlocation: data,
      };

    case RECEIVE_GET_SECTOR_LIST:
      return {
        ...state,
        SectorData: data,
      };

    case RECEIVE_GET_JOB:
      return {
        ...state,
        GetJobData: data,
      };

    case RECEIVE_GET_JOBLIST:
      return {
        ...state,
        GetJobListData: data,
      };

    case RESET_GET_JOBLIST:
      return {
        ...state,
        GetJobListData: null,
      };

    case RECEIVE_GET_CANDIDATE:
      return {
        ...state,
        GetCandidateData: data,
      };

    case RECEIVE_GET_PRODUCTS:
      return {
        ...state,
        GetProductsData: data,
      };

    default:
      return state;
  }
};
