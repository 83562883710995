import React from 'react';
import PhoneInput from "react-phone-input-2";
import it from "react-phone-input-2/lang/it.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import loaderLogo from "../../images/Iphone-spinner-2.gif";

function Referent(props) {
    const {
        reference,
        errorreference,
        handleInputReference,
        formFocusRefs,
        validateEmail,
        loadingForRefer,
        validateFname,
        validateLname,
        validatePhone,
        handleAddReference,
        handleRemoveReference,
        mystyle,
        t,
        handleOnEmailVerify,
        emailVerify
    } = props;

    return (
        <div className="reference_form">

            <div className="reference_form_title">
                <h6>Referenti</h6>
            </div>
            <div className="reference_form_loder">
                {loadingForRefer && <div className="loader">
                    <div className='loader_inner'>
                        <img src={loaderLogo} />
                    </div>
                </div>}
                <div className="reference_form_inner">

                    {reference.map((x, i) => {
                        return (
                            <div key={i} className="row reference_row g-4">
                                <div className="reference_delete_btn">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={() => handleRemoveReference(i)}>
                                        <FontAwesomeIcon icon={faTrashCan} />
                                    </button>
                                </div>
                                <div className="reference_col form-group col-md-6 col-12">
                                    <label htmlFor={`email${i}`} className="label">
                                        {t("register.email")}
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control set_max_width ${errorreference[i]?.email ? "error" : ""}`}
                                        placeholder={t("register.email")}
                                        id={`email${i}`}
                                        name="email"
                                        ref={(el) => {
                                            formFocusRefs.reference.current[i] =
                                                formFocusRefs.reference.current[i] || {};
                                            formFocusRefs.reference.current[i].email = el;
                                        }}
                                        value={x.email || ""}
                                        onChange={(e) => handleInputReference(e, i)}
                                        onBlur={() => validateEmail(i)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                                e.preventDefault();
                                                handleOnEmailVerify(x, i);
                                            }
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleOnEmailVerify(x, i);
                                            }
                                        }}
                                    />
                                    {errorreference[i]?.email && (
                                        <div style={mystyle}>{errorreference[i].email}</div>
                                    )}
                                </div>

                                {emailVerify[i] && (
                                    <>
                                        <div className="reference_col form-group col-md-6 col-12">
                                            <label htmlFor={`first_name${i}`} className="label">
                                                {t("register.name")}
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control set_max_width ${errorreference[i]?.first_name ? "error" : ""}`}
                                                placeholder={t("register.name")}
                                                id={`first_name${i}`}
                                                name="first_name"
                                                ref={(el) => {
                                                    formFocusRefs.reference.current[i] =
                                                        formFocusRefs.reference.current[i] || {};
                                                    formFocusRefs.reference.current[i].first_name = el;
                                                }}
                                                value={x.first_name || ""}
                                                onChange={(e) => handleInputReference(e, i)}
                                                onBlur={() => validateFname(i)}
                                            />
                                            {errorreference[i]?.first_name && (
                                                <div style={mystyle}>{errorreference[i].first_name}</div>
                                            )}
                                        </div>

                                        <div className="reference_col form-group col-md-6 col-12">
                                            <label htmlFor={`last_name${i}`} className="label">
                                                {t("register.surname")}
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control set_max_width ${errorreference[i]?.last_name ? "error" : ""}`}
                                                placeholder={t("register.surname")}
                                                id={`last_name${i}`}
                                                name="last_name"
                                                ref={(el) => {
                                                    formFocusRefs.reference.current[i] =
                                                        formFocusRefs.reference.current[i] || {};
                                                    formFocusRefs.reference.current[i].last_name = el;
                                                }}
                                                value={x.last_name || ""}
                                                onChange={(e) => handleInputReference(e, i)}
                                                onBlur={() => validateLname(i)}
                                            />
                                            {errorreference[i]?.last_name && (
                                                <div style={mystyle}>{errorreference[i].last_name}</div>
                                            )}
                                        </div>

                                        <div className="reference_col form-group col-md-6 col-12">
                                            <label htmlFor={`phone${i}`} className="label">
                                                Numero di telefono
                                            </label>
                                            <PhoneInput
                                                className={`form-control set_max_width numero_cellulare ${errorreference[i]?.phone ? "error" : ""}`}
                                                country={"it"}
                                                name="phone"
                                                localization={it}
                                                value={x.phone || ""}
                                                ref={(el) => {
                                                    formFocusRefs.reference.current[i] =
                                                        formFocusRefs.reference.current[i] || {};
                                                    formFocusRefs.reference.current[i].phone = el;
                                                }}
                                                placeholder={t("register.cellphone")}
                                                onChange={(e) => handleInputReference(e, i)}
                                                onBlur={() => validatePhone(i)}
                                                enableLongNumbers={18}
                                            />
                                            {errorreference[i]?.phone && (
                                                <div style={mystyle}>{errorreference[i].phone}</div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}

                    <div className="reference_add_btn_sec">
                        <div className="reference_add_btn_inner">
                            <div className="reference_add_btn">
                                <button
                                    className="btn"
                                    type="button"
                                    onClick={handleAddReference}>
                                    AGGIUNGI UN ALTRO REFERENTE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Referent;