import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetSectorList } from "../../../Redux/actions";
import CreatableSelect from "react-select/creatable";

function SectorLevel(props) {
  const {
    selectedOptionsSector,
    handleSectorLevelChange,
    validateSector,
    sectorRef,
    errorSector,
    handleSubSectorLevelChange,
    validateSubSector,
    subsectorRef,
    errorSubSector,
    isLoading,
    mystyle,
    t,
  } = props;

  const [sectorsLevel, setSectorsLevel] = useState([]);
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([]);
  const isClearable = true;

  useEffect(() => {
    props.requestGetSectorList();
  }, []);

  useEffect(() => {
    const sectorsLevelData = props.candidate.SectorData;
    if (sectorsLevelData?.data?.Status === "Success") {
      const uniqueSectors = {};

      sectorsLevelData.data.Message.forEach((sector) => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName,
          };
        }
      });
      const transformedSectorlevelData = Object.values(uniqueSectors);
      setSectorsLevel(transformedSectorlevelData);

      //   const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
      //     (level) => level.subSector
      //   ).map((level) => ({
      //     sectorName: level.sectorName,
      //     subSector: level.subSector,
      //   }));

      //   setSubSubSectorsLevel(filteredSubMenuOptions);
    }
  }, [props.candidate.SectorData]);

  const selectedSubmenuOption = subSubSectorsLevel.filter(
    (option) => option.sectorName === selectedOptionsSector?.submenu
  );

  //   const hasSubSubmenuOptions =
  //     selectedSubmenuOption && selectedSubmenuOption?.length > 0;

  return (
    <>
      <div className="registration_col form-group col-md-6 col-12">
        <label htmlFor="sector" className="label">
          Settore
        </label>
        <CreatableSelect
          name="sector"
          className={`form-control set_max_width select_dropdown  ${
            errorSector ? "error" : ""
          } `}
          options={sectorsLevel}
          onChange={(...args) => {
            handleSectorLevelChange(...args);
            const optList = props.candidate.SectorData?.data.Message;
            const subSectorsList = optList
              .filter((sector) => sector.sectorName === args[0]?.label)
              .filter((s) => !!s.subSector);
            if (subSectorsList) {
              setSubSubSectorsLevel(subSectorsList);
            }
          }}
          value={
            selectedOptionsSector.submenu
              ? {
                  label: selectedOptionsSector.submenu.label,
                  value: selectedOptionsSector.submenu.value,
                }
              : null
          }
          ref={sectorRef}
          placeholder="Seleziona un Settore"
          onBlur={validateSector}
          isClearable={isClearable}
          isLoading={isLoading}
        />
        {errorSector && <div style={mystyle}>{errorSector}</div>}
      </div>

      {subSubSectorsLevel?.length ? (
        <>
          <div className="registration_col form-group col-md-6 col-12">
            <label>Sottosettore</label>
            <select
              name="sector"
              value={
                subSubSectorsLevel.find(
                  (subOption) =>
                    subOption.subSector === selectedOptionsSector?.subSubmenu
                )?.subSector || ""
              }
              onChange={handleSubSectorLevelChange}
              onBlur={validateSubSector}
              ref={subsectorRef}
              className={`form-control set_max_width mt-2 ${
                errorSubSector ? "error" : ""
              }`}
            >
              <option value="">Seleziona un Sottosettore</option>
              {subSubSectorsLevel?.map((subOption, index) => (
                <option key={index} value={subOption.subSector}>
                  {subOption.subSector}
                </option>
              ))}
            </select>
            {errorSubSector && <div style={mystyle}>{errorSubSector}</div>}
          </div>
        </>
      ) : (
        <div className="registration_col form-group col-md-6 col-12">
          <label htmlFor="products_marketed" className="label">
            Sottosettori
          </label>
          <input
            type="text"
            className={`form-control set_max_width ${
              errorSubSector ? "error" : ""
            }`}
            placeholder="Sottosettori"
            id="products_marketed"
            name="products_marketed"
            ref={subsectorRef}
            value={selectedOptionsSector?.subSubmenu?.label || ""}
            onChange={(e) =>
              handleSubSectorLevelChange({ label: e.target.value })
            }
            onBlur={validateSubSector}
          />
          {errorSubSector && <div style={mystyle}>{errorSubSector}</div>}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetSectorList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SectorLevel);
